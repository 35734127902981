import {ReactNode} from 'react';

export interface ToastService {
    addToast: (message: ReactNode, options?: ToastOptions) => Toast;
    removeToast: (toastId: Toast['id']) => void;
    getToasts: () => Toast[];
    watch: (watcher: ToastWatcher) => void;
    unwatch: (watcher: ToastWatcher) => void;
}

export interface ToastOptions {
    timeout?: number;
}

export type ToastWatcher = () => void;

export interface Toast {
    id: number;
    message: ReactNode;
}

const DEFAULT_TIMEOUT = 5000;

export function createToastService({
    timeout: defaultTimeout = DEFAULT_TIMEOUT
}: ToastOptions = {}): ToastService {

    let toastId = 0;
    const toasts = [] as Toast[];
    const watchersSet = new Set<ToastWatcher>();

    const getToasts = (): Toast[] => {
        return toasts;
    };

    const addToast = (message: ReactNode, {timeout = defaultTimeout}: ToastOptions = {}): Toast => {
        const id = toastId++;
        const newToast = {id, message};
        toasts.push(newToast);
        runWatchers();
        if (timeout) {
            setTimeout(() => {
                removeToast(newToast.id);
            }, timeout);
        }
        return newToast;
    };

    const removeToast = (toastId: Toast['id']): void => {
        const toastIndex = toasts.findIndex((toast: Toast): boolean => {
            return toast.id === toastId;
        });
        if (toastIndex >= 0) {
            toasts.splice(toastIndex, 1);
        }
        runWatchers();
    };

    const watch = (watcher: ToastWatcher): void => {
        watchersSet.add(watcher);
    };

    const unwatch = (watcher: ToastWatcher): void => {
        watchersSet.delete(watcher);
    };

    const runWatchers = (): void => {
        watchersSet.forEach((watcher: ToastWatcher) => {
            watcher();
        });
    };

    return {
        getToasts,
        addToast,
        removeToast,
        watch,
        unwatch
    };

}