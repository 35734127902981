import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {TabGroupDirection} from './tab-group';

export type TabClassName = 'tab' | 'tooltipWrapper' | 'icon' | 'label' | 'asterisk' | 'is-active' | `direction-${TabGroupDirection}`;

export interface TabCSSProps extends CSSThemeProps {

}

export function TabCSS({theme}: TabCSSProps) {
    return css<TabClassName>`

        .tab {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.small};
            border-radius: ${theme.borderRadius.medium};
            font-weight: bold;
            padding: 0 ${theme.spacing.small};
            height: 2.25em;
            cursor: pointer;
            color: ${theme.color.gray};

            &:hover {
                color: ${theme.color.black};
            }

            &.is-active {
                cursor: default;
                background: ${theme.color.white};
                box-shadow: ${theme.boxShadow.small};
                color: ${theme.color.black};
            }
        }

        .tooltipWrapper {
            flex: 1;
        }

        .icon {
            vertical-align: middle;
        }

        .label {
            white-space: nowrap;
        }

        .asterisk {
            position: absolute;
            right: ${theme.spacing.verySmall};
            top: ${theme.spacing.verySmall};
        }

        .direction-horizontal {
            flex: 1;
        }

    `;
}