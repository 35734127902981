import {ComponentExtensionOptions} from '@webaker/app';
import {HiddenComponent} from './hidden-component';
import {HiddenComponentViewWrapper} from './hidden-component-view-wrapper';

export const HIDDEN_COMPONENT_EXTENSION_NAME = 'hidden';

export function createHiddenComponentOptions(): ComponentExtensionOptions<HiddenComponent> {
    return {
        name: HIDDEN_COMPONENT_EXTENSION_NAME,
        wrapper: HiddenComponentViewWrapper
    };
}