import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type LoadingBarClassName = 'loadingBar' | 'progressBar' | 'is-active' | 'has-animation';

export interface LoadingBarCSSProps extends CSSThemeProps {

}

export function LoadingBarCSS({theme}: LoadingBarCSSProps) {
    return css<LoadingBarClassName>`

        .loadingBar {
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: ${theme.color.white};
            box-shadow: ${theme.boxShadow.medium};
            pointer-events: none;
            transition-property: opacity;
            transition-duration: 400ms;
            transition-delay: 100ms;
            transition-timing-function: linear;
            opacity: 0;
        }

        .is-active {
            opacity: 1;
            transition-delay: 0ms;
        }

        .progressBar {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            background: ${theme.color.info};
        }

        .has-animation .progressBar {
            transition: width 100ms linear;
        }

    `;
}