import {ApiClientFactory} from '@webaker/package-api/client';
import {USER_MANAGER_PAGE_API_NAME, UserManagerPageApi} from '../user-manager-page-api';

export interface UserManagerPageClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createUserManagerPageClientApi({apiClientFactory}: UserManagerPageClientApiDeps): UserManagerPageApi {
    return apiClientFactory.createApi<UserManagerPageApi>(USER_MANAGER_PAGE_API_NAME, {
        getUsers: [],
        saveUser: ['user'],
        changeUserPassword: ['userId', 'password'],
        getAllUserRoles: [],
        syncUserRoles: ['userRoles']
    });
}