import {Page} from '@webaker/app';
import {WEBSITE_ICON_ID_SETTING, WebsiteSettingsMap} from '@webaker/module-website-settings/editor';
import {FileStore} from '@webaker/package-file';
import {SettingsStore} from '@webaker/package-settings';
import {spiderIconIco} from '@webaker/package-spider';

export const TITLE_METADATA_KEY = 'title';
export const FAVICON_METADATA_KEY = 'favicon';

export interface PageMetadataService {
    getMetadata: (key: string, page: Page | null) => string | null;
    overrideMetadata: (key: string, resolver: PageMetadataResolver) => void;
}

export type PageMetadataResolver = (page: Page | null) => string;

export interface PageMetadataServiceDeps {
    fileStore: FileStore;
    settingsStore: SettingsStore;
}

export interface PageMetadataServiceConfig {
    appName: string;
}

export function createPageMetadataService({fileStore, settingsStore}: PageMetadataServiceDeps, {appName}: PageMetadataServiceConfig): PageMetadataService {

    const metadataResolversMap = new Map<string, PageMetadataResolver>();

    const getMetadata = (key: string, page: Page | null): string | null => {
        const resolver = metadataResolversMap.get(key);
        return resolver ? resolver(page) : null;
    };

    const overrideMetadata = (key: string, resolver: PageMetadataResolver): void => {
        metadataResolversMap.set(key, resolver);
    };

    metadataResolversMap.set(TITLE_METADATA_KEY, (page: Page | null): string => {
        return page?.title ?? appName;
    });

    metadataResolversMap.set(FAVICON_METADATA_KEY, (page: Page | null): string => {
        const pageIconId = settingsStore.getValue<WebsiteSettingsMap, typeof WEBSITE_ICON_ID_SETTING>(WEBSITE_ICON_ID_SETTING);
        const file = pageIconId ? fileStore.getFileById(pageIconId) : null;
        return file?.url ?? spiderIconIco;
    });

    return {
        getMetadata,
        overrideMetadata
    };

}
