import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SelectBoxClassName = 'selectBox' | 'arrowButton' | 'is-open';

export interface SelectBoxCSSProps extends CSSThemeProps {

}

export function SelectBoxCSS({theme}: SelectBoxCSSProps) {
    return css<SelectBoxClassName>`

        .selectBox {

        }
        
        .arrowButton {

        }

        .is-open {
            outline: 1px solid ${theme.color.info};
        }

    `;
}