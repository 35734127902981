import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, useEffect, useState} from 'react';
import {SpiderNetBackgroundCSS} from './spider-net-background-css';

export interface SpiderNetBackgroundProps {
    interactive?: boolean;
    className?: string;
    elementRef?: MutableRefObject<HTMLDivElement>;
}

export function SpiderNetBackground({interactive, className, elementRef}: SpiderNetBackgroundProps) {

    const [mouseDeltaX, setMouseDeltaX] = useState(0);
    const [mouseDeltaY, setMouseDeltaY] = useState(0);
    const css = useThemedCSS(SpiderNetBackgroundCSS, {
        deltaX: mouseDeltaX,
        deltaY: mouseDeltaY
    });

    const handleMouseMove = (event: MouseEvent) => {
        setMouseDeltaX(event.clientX - window.innerWidth / 2);
        setMouseDeltaY(event.clientY - window.innerHeight / 2);
    };

    const handleTouchMove = (event: TouchEvent) => {
        setMouseDeltaX(event.touches[0].clientX - window.innerWidth / 2);
        setMouseDeltaY(event.touches[0].clientY - window.innerHeight / 2);
    };

    useEffect(() => {
        if (interactive) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('touchmove', handleTouchMove);
        } else {
            setMouseDeltaX(0);
            setMouseDeltaY(0);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [interactive]);

    return (
        <div ref={elementRef}
             className={mergeClassNames(css['spiderNetBackground'], className)}>
            <div className={css['pattern']}/>
        </div>
    );

}