import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SliderButtonClassName = 'sliderButton' | 'dragHandleIcon';

export interface SliderButtonCSSProps extends CSSThemeProps {

}

export function SliderButtonCSS({theme}: SliderButtonCSSProps) {
    return css<SliderButtonClassName>`

        .sliderButton {
            cursor: ew-resize;

            &:disabled {
                color: ${theme.color.gray};
                cursor: default;
            }
        }

        .dragHandleIcon {
            transform: rotateZ(90deg);
        }

    `;
}