import {PageOptions} from '@webaker/app';
import {SETTINGS_PAGE_TYPE, SettingsPage} from './settings-page';

export interface SettingsPageOptions extends PageOptions<SettingsPage> {

}

export function createSettingsPageOptions(): SettingsPageOptions {
    return {

        type: SETTINGS_PAGE_TYPE,

        static: true,

        editor: true,

        ssr: false,

        view: async () => (await import('@webaker/module-settings-page/editor')).SettingsPageView

    };
}