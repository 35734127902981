import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useDocumentEvent, useHotkey} from '@webaker/package-utils';
import {FormEvent, MouseEvent, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState, WheelEvent as ReactWheelEvent} from 'react';
import {ASTERISK} from './asterisk';
import {AutocompleteCSS} from './autocomplete-css';

export interface AutocompleteProps<T = any> {
    options: AutocompleteOption<T>[];
    value?: T;
    search?: boolean;
    onSelect: (event: AutocompleteSelectEvent<T>) => void;
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
}

export interface AutocompleteOption<T = any> {
    value: T;
    label: string;
    icon?: ReactNode;
    asterisk?: boolean;
}

export interface AutocompleteSelectEvent<T = any> {
    value: T;
}

export function Autocomplete<T = any>({options, value, search, onSelect, className, elementRef}: AutocompleteProps<T>) {

    const css = useThemedCSS(AutocompleteCSS, {});
    const [activeIndex, setActiveIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const optionsRef = useRef<Map<T, HTMLElement>>(new Map());

    const selectOptionByValue = useCallback((event: MouseEvent, value: T) => {
        onSelect?.({value});
        event.stopPropagation();
    }, [onSelect]);

    const filteredOptions = useMemo(() => {
        return options.filter((option: AutocompleteOption): boolean => {
            return option.label.toLowerCase().startsWith(searchQuery.toLowerCase());
        });
    }, [options, searchQuery]);

    const activeOption = filteredOptions[activeIndex];

    const setActiveByValue = useCallback((activeValue: T) => {
        const index = filteredOptions.findIndex((option: AutocompleteOption): boolean => {
            return option.value === activeValue;
        });
        setActiveIndex(index < 0 ? 0 : index);
    }, [filteredOptions]);

    const setOptionRef = useCallback((value: T, element: HTMLElement | null) => {
        if (element) {
            optionsRef.current.set(value, element);
        } else {
            optionsRef.current.delete(value);
        }
    }, []);

    const handleSearchChange = useCallback((event: FormEvent) => {
        setSearchQuery((event.target as HTMLInputElement).value);
        setActiveIndex(0);
    }, []);

    const handleWheel = useCallback((event: ReactWheelEvent) => {
        event.stopPropagation();
    }, []);

    const scrollToOption = useCallback((value: T | undefined) => {
        if (value) {
            optionsRef.current.get(value)?.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, []);

    useEffect(() => {
        if (typeof value !== 'undefined') {
            setActiveByValue(value);
        }
    }, [value]);

    useHotkey('ArrowUp', () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
            scrollToOption(filteredOptions[activeIndex - 1]?.value);
        }
    }, [activeIndex, filteredOptions]);

    useHotkey('ArrowDown', () => {
        if (activeIndex < filteredOptions.length - 1) {
            setActiveIndex(activeIndex + 1);
            scrollToOption(filteredOptions[activeIndex + 1]?.value);
        }
    }, [activeIndex, filteredOptions]);

    useHotkey('Enter', () => {
        if (activeOption) {
            onSelect?.({value: activeOption.value});
        }
    }, [onSelect, activeOption]);

    useDocumentEvent('wheel', (event: WheelEvent) => {
        if (event.deltaY < 0 && activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
            scrollToOption(filteredOptions[activeIndex - 1]?.value);
        } else if (event.deltaY > 0 && activeIndex < filteredOptions.length - 1) {
            setActiveIndex(activeIndex + 1);
            scrollToOption(filteredOptions[activeIndex + 1]?.value);
        }
    }, [activeIndex, filteredOptions]);

    return (
        <div ref={elementRef as any}
             className={mergeClassNames(css['autocomplete'], className)}
             onWheel={handleWheel}>
            {search && (
                <input type="text"
                       value={searchQuery}
                       onChange={handleSearchChange}
                       className={css['search']}
                       autoFocus={true}/>
            )}
            {filteredOptions.map((option: AutocompleteOption) => (
                <div ref={(element) => setOptionRef(option.value, element)}
                     key={option.value as any}
                     className={mergeClassNames(
                         css['option'],
                         option === activeOption && css['is-active']
                     )}
                     onMouseDown={event => selectOptionByValue(event, option.value)}
                     onMouseOver={() => setActiveByValue(option.value)}>
                    {option.icon && (
                        <span className={css['icon']}>
                           {option.icon}
                       </span>
                    )}
                    {option.label}
                    {option.asterisk && ASTERISK}
                </div>
            ))}
        </div>
    );

}