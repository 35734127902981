import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';

export type LabelClassName = 'label' | 'asterisk' | 'is-disabled';

export function LabelCSS({theme}: CSSThemeProps) {
    return css<LabelClassName>`

        .label {
            color: ${theme.color.dark};
            font-weight: bold;
            min-height: 2.25em;
            line-height: 2.25em;
            display: flex;
            gap: ${theme.spacing.medium};

            &.is-disabled {
                color: ${theme.color.light};
            }
        }

        .asterisk {
            margin-left: -${theme.spacing.small};
        }

    `;
}

export interface LabelPrivateCSSProps extends CSSThemeProps {
    margin: CSSThemeSize;
}

export function LabelPrivateCSS({theme, margin}: LabelPrivateCSSProps) {
    return css<LabelClassName>`

        .label {
            margin: ${theme.spacing[margin]} 0;
        }

    `;
}