import {PageRegistry} from '@webaker/app';
import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {WebsiteManagerPageApi} from '../website-manager-page-api';
import {createWebsiteManagerPageOptions} from '../website-manager-page-options';
import {createWebsiteManagerPageClientApi} from './website-manager-page-client-api';

export interface ProvidedWebsiteManagerPageClientDeps {
    websiteManagerPageApi: WebsiteManagerPageApi;
}

export interface RequiredWebsiteManagerPageClientDeps {
    apiClientFactory: ApiClientFactory;
    pageRegistry: PageRegistry;
}

export interface WebsiteManagerPageClientDeps extends ProvidedWebsiteManagerPageClientDeps, RequiredWebsiteManagerPageClientDeps {

}

export interface WebsiteManagerPageClientConfig {

}

export type WebsiteManagerPageClientProvider = Provider<WebsiteManagerPageClientDeps, WebsiteManagerPageClientConfig>;

export function createWebsiteManagerPageClientProvider(): WebsiteManagerPageClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('websiteManagerPageApi', ({resolve}) => {
                return createWebsiteManagerPageClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const loginPageOptions = createWebsiteManagerPageOptions();
            pageRegistry.registerPage(loginPageOptions);
        }

    };
}