import {ADMIN_USER_ROLE_ID} from './admin-user-role';
import {GUEST_USER_ROLE_ID} from './guest-user-role';
import {User} from './user';
import {UserRole} from './user-role';

export interface UserHelper {
    hasUserRole: (user: User | null, roleId: UserRole['id']) => boolean;
    isAdmin: (user: User | null) => boolean;
    isGuest: (user: User | null) => boolean;
}

export interface UserHelperDeps {

}

export function createUserHelper({}: UserHelperDeps = {}): UserHelper {

    const hasUserRole = (user: User | null, roleId: UserRole['id']): boolean => {
        return getUserRolesIds(user).includes(roleId);
    };

    const isAdmin = (user: User | null): boolean => {
        return hasUserRole(user, ADMIN_USER_ROLE_ID);
    };

    const isGuest = (user: User | null): boolean => {
        return hasUserRole(user, GUEST_USER_ROLE_ID);
    };

    const getUserRolesIds = (user: User | null): UserRole['id'][] => {
        if (!user) {
            return [GUEST_USER_ROLE_ID];
        }
        return user.rolesIds;
    };

    return {
        hasUserRole,
        isAdmin,
        isGuest
    };

}