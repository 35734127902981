import {DEVELOPMENT, getAppMode, PRODUCTION} from '../utils/app-mode';

export interface FeaturesConfig {
    encodeState: boolean;
    minifyCSS: boolean;
    apiDelay: number;
}

export function getFeaturesConfig(): FeaturesConfig {

    const appMode = getAppMode();
    const encodeState = appMode === PRODUCTION;
    const minifyCSS = appMode === PRODUCTION;
    const apiDelay = appMode === DEVELOPMENT && process.env.API_DELAY ? parseInt(process.env.API_DELAY) : 0;

    return {
        encodeState,
        minifyCSS,
        apiDelay
    };

}