import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject} from 'react';
import {spiderJumpingPng} from '../assets/spider-jumping';
import {spiderLookingDownPng} from '../assets/spider-looking-down';
import {spiderPointingUpPng} from '../assets/spider-pointing-up';
import {spiderStandingPng} from '../assets/spider-standing';
import {spiderStretchingPng} from '../assets/spider-stretching';
import {spiderWavingPng} from '../assets/spider-waving';
import {SpiderImageCSS, SpiderImagePrivateCSS} from './spider-image-css';

export interface SpiderImageProps {
    variant?: SpiderImageVariant;
    size?: CSSThemeSize;
    className?: string;
    elementRef?: MutableRefObject<HTMLImageElement>;
}

export const SPIDER_STANDING_VARIANT = 'standing';
export const SPIDER_WAVING_VARIANT = 'waving';
export const SPIDER_STRETCHING_VARIANT = 'stretching';
export const SPIDER_JUMPING_VARIANT = 'jumping';
export const SPIDER_LOOKING_DOWN_VARIANT = 'lookingDown';
export const SPIDER_POINTING_UP_VARIANT = 'pointingUp';

export const SPIDER_MODEL_WIDTH = 256;
export const SPIDER_MODEL_HEIGHT = 256;

const SPIDER_VARIANTS_MAP = {
    [SPIDER_STANDING_VARIANT]: spiderStandingPng,
    [SPIDER_WAVING_VARIANT]: spiderWavingPng,
    [SPIDER_STRETCHING_VARIANT]: spiderStretchingPng,
    [SPIDER_JUMPING_VARIANT]: spiderJumpingPng,
    [SPIDER_LOOKING_DOWN_VARIANT]: spiderLookingDownPng,
    [SPIDER_POINTING_UP_VARIANT]: spiderPointingUpPng
};

export type SpiderImageVariant = keyof typeof SPIDER_VARIANTS_MAP;

export function SpiderImage({variant = SPIDER_STANDING_VARIANT, size = 'medium', className, elementRef}: SpiderImageProps) {

    const css = useThemedCSSBundle([
        SpiderImageCSS,
        SpiderImagePrivateCSS
    ], {
        size
    });

    const imageUrl = SPIDER_VARIANTS_MAP[variant];

    return (
        <img ref={elementRef}
             src={imageUrl}
             alt="Webaker"
             width={SPIDER_MODEL_WIDTH}
             height={SPIDER_MODEL_HEIGHT}
             className={mergeClassNames(css['spiderImage'], className)}/>
    );

}