import {CSSThemeStore} from '@webaker/package-css-theme';
import {Provider} from '@webaker/package-deps';
import {ThemeRenderer} from '@webaker/package-theme';
import {createDesktopThemeRenderer, DesktopThemeRenderer} from '../desktop-theme-renderer';
import {DESKTOP_THEME_VARIANT} from '../desktop-theme-variant';

export interface ProvidedDesktopThemeClientDeps {
    desktopThemeRenderer: DesktopThemeRenderer;
}

export interface RequiredDesktopThemeClientDeps {
    cssThemeStore: CSSThemeStore;
    themeRenderer: ThemeRenderer;
}

export interface DesktopThemeClientDeps extends ProvidedDesktopThemeClientDeps, RequiredDesktopThemeClientDeps {

}

export interface DesktopThemeClientConfig {

}

export type DesktopThemeClientProvider = Provider<DesktopThemeClientDeps, DesktopThemeClientConfig>;

export function createDesktopThemeClientProvider(): DesktopThemeClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('desktopThemeRenderer', ({resolve}) => {
                return createDesktopThemeRenderer({
                    cssThemeStore: resolve('cssThemeStore')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const themeRenderer = resolve('themeRenderer');
            const desktopThemeRenderer = resolve('desktopThemeRenderer');
            themeRenderer.registerRenderer(DESKTOP_THEME_VARIANT, desktopThemeRenderer);
        }

    };
}