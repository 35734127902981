import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor, contrast} from '@webaker/package-utils';

export type FormInfoClassName = 'formInfo' | 'content' | 'icon';

export interface FormInfoCSSProps extends CSSThemeProps {

}

export function FormInfoCSS({theme}: FormInfoCSSProps) {

    const arrowSize = '5px';

    return css<FormInfoClassName>`

        .formInfo {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: ${theme.spacing.medium};
            margin: ${arrowSize} 0;
            border-radius: ${theme.borderRadius.medium};
            box-shadow: ${theme.boxShadow.verySmall};
            padding: ${theme.spacing.medium};
            position: relative;

            &:before {
                content: ' ';
                width: 0;
                height: 0;
                position: absolute;
                top: -${arrowSize};
                left: ${theme.spacing.medium};
                border-style: solid;
                border-width: 0 ${arrowSize} ${arrowSize} ${arrowSize};
                border-color: transparent;
            }
        }

        .content {

        }

        .icon {

        }

    `;

}

export interface FormInfoPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function FormInfoPrivateCSS({theme, color}: FormInfoPrivateCSSProps) {

    return css<FormInfoClassName>`

        .formInfo {
            color: ${contrast(theme.color[color])};
            background: radial-gradient(
                circle at ${theme.spacing.medium} 0,
                ${theme.color[color]},
                ${adjustColor(theme.color[color], {alpha: 0.7})}
            );

            &:before {
                border-bottom-color: ${theme.color[color]};
            }
        }

    `;

}