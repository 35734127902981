import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type FileListContentClassName = 'fileListContent' | 'overlay' | 'icon';

export interface FileListContentCSSProps extends CSSThemeProps {

}

export function NodeListContentCSS({theme}: FileListContentCSSProps) {
    return css<FileListContentClassName>`

        .fileListContent {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            position: relative;
            background: ${theme.color.white};
        }

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: ${color(theme.color.white, {alpha: 0.5})};
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.large};
            font-size: ${theme.fontSize.large};
            font-weight: bold;
            color: ${theme.color.light};
        }

        .icon {
            font-size: ${theme.fontSize.veryLarge};
        }

    `;
}