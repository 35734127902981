import {ThemeCustomRenderer} from '@webaker/package-theme';

export interface HoverThemeRenderer extends ThemeCustomRenderer {

}

export interface HoverThemeRendererDeps {

}

export function createHoverThemeRenderer({}: HoverThemeRendererDeps = {}): HoverThemeRenderer {
    return (selector: string, properties: string): string => {
        return `${selector}:hover{${properties}}`;
    };
}