export interface LoadingService {
    startLoading: () => void;
    stopLoading: () => void;
    isLoading: () => boolean;
    watch: (watcher: LoadingWatcher) => void;
    unwatch: (watcher: LoadingWatcher) => void;
}

export type LoadingWatcher = () => void;

export function createLoadingService(): LoadingService {

    let loadingCount = 0;
    const watchersSet = new Set<LoadingWatcher>();

    const startLoading = (): void => {
        loadingCount++;
        runWatchers();
    };

    const stopLoading = (): void => {
        loadingCount--;
        runWatchers();
    };

    const isLoading = (): boolean => {
        return loadingCount > 0;
    };

    const watch = (watcher: LoadingWatcher): void => {
        watchersSet.add(watcher);
    };

    const unwatch = (watcher: LoadingWatcher): void => {
        watchersSet.delete(watcher);
    };

    const runWatchers = (): void => {
        watchersSet.forEach((watcher: LoadingWatcher): void => {
            watcher();
        });
    };

    return {
        startLoading,
        stopLoading,
        isLoading,
        watch,
        unwatch
    };

}