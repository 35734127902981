import {CSSThemeStore} from '@webaker/package-css-theme';
import {ThemeCustomRenderer} from '@webaker/package-theme';

export interface DesktopThemeRenderer extends ThemeCustomRenderer {

}

export interface DesktopThemeRendererDeps {
    cssThemeStore: CSSThemeStore;
}

export interface DesktopThemeRendererConfig {
    container?: boolean;
}

export function createDesktopThemeRenderer({cssThemeStore}: DesktopThemeRendererDeps, {container}: DesktopThemeRendererConfig = {}): DesktopThemeRenderer {
    return (selector: string, properties: string): string => {
        const theme = cssThemeStore.getCurrentCSSTheme();
        const desktopWidth = theme.deviceWidth.desktop;
        return [
            `@media(width>=${desktopWidth}){${selector}{${properties}}}`,
            container ? `@container(width>=${desktopWidth}){${selector}{${properties}}}` : ''
        ].join('');
    };
}