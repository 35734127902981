import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, parseNumber, parseUnit, stringifyNumber, useDocumentEvent, useHotkey} from '@webaker/package-utils';
import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState, WheelEvent as ReactWheelEvent} from 'react';
import {Input, InputChangeEvent} from './input';
import {NumberFieldCSS} from './number-field-css';
import {SliderButton, SliderButtonChangeEvent} from './slider-button';

export interface NumberFieldProps {
    value: number | string | null;
    onChange?: (event: NumberFieldChangeEvent) => void;
    default?: number | string;
    min?: number;
    max?: number;
    placeholder?: string | null;
    clearable?: boolean;
    disabled?: boolean;
    className?: string;
    units?: string[];
    step?: number;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface NumberFieldChangeEvent {
    value: string | null;
    number: number | null;
    unit: string | null;
}

export function NumberField({
    value,
    onChange,
    min = -Infinity,
    max = Infinity,
    default: defaultValue = 0,
    placeholder,
    clearable,
    disabled,
    className,
    units,
    step = getDefaultUnitStep(parseUnit(value) ?? parseUnit(defaultValue) ?? units?.[0] ?? null),
    inputRef
}: NumberFieldProps) {

    const css = useThemedCSS(NumberFieldCSS, {});
    const internalInputRef = useRef<HTMLInputElement | null>(null);
    const actualInputRef = inputRef ?? internalInputRef;
    const unit = useMemo(() => {
        return parseUnit(value) ?? parseUnit(defaultValue) ?? units?.[0] ?? null;
    }, [value, defaultValue, units?.[0]]);
    const number = useMemo<number | null>(() => {
        return value === null ? null : parseNumber(value, {min, max, step});
    }, [value, min, max, step]);

    const [localNumber, setLocalNumber] = useState<number | null>(number);
    const isLocalNumberValid = localNumber === number;

    step ??= getDefaultUnitStep(unit);

    const handleInputChange = useCallback((event: InputChangeEvent) => {
        const newLocalNumber = event.value ? parseNumber(event.value) : null;
        const newNumber = event.value ? parseNumber(event.value, {min, max, step}) : null;
        setLocalNumber(newLocalNumber);
        if (newNumber !== newLocalNumber) {
            return;
        }
        const newUnitQuery = parseUnit(event.value);
        const newUnit = newUnitQuery ? units?.find((unit: string): boolean => {
            return unit.startsWith(newUnitQuery);
        }) ?? unit : unit;
        onChange?.({
            value: stringifyNumber(newNumber, newUnit),
            number: newNumber,
            unit: newUnit
        });
    }, [onChange, unit, units, min, max, step]);

    const handleInputBlur = useCallback(() => {
        const newNumber = localNumber === null ? null : parseNumber(localNumber, {min, max, step});
        onChange?.({
            value: stringifyNumber(newNumber, unit),
            number: newNumber,
            unit: unit
        });
    }, [localNumber, onChange, unit, units, min, max, step]);

    const handleDocumentWheel = useCallback((event: WheelEvent) => {
        if (document.activeElement === actualInputRef.current) {
            const delta = event.deltaY < 0 ? 1 : -1;
            const newNumber = parseNumber(number === null ? defaultValue : number + step * delta, {min, max, step});
            onChange?.({
                value: stringifyNumber(newNumber, unit),
                number: newNumber,
                unit
            });
            event.preventDefault();
        }
    }, [onChange, unit, min, max, step, number]);

    const handleSliderButtonChange = useCallback((event: SliderButtonChangeEvent) => {
        const newNumber = event.value;
        onChange?.({
            value: stringifyNumber(newNumber, unit),
            number: newNumber,
            unit
        });
    }, [onChange, unit]);

    const handleUnitButtonClick = useCallback(() => {
        if (units && unit) {
            const nextIndex = (units.indexOf(unit) + 1) % units.length;
            const newUnit = units[nextIndex];
            onChange?.({
                value: stringifyNumber(number, newUnit),
                number,
                unit: newUnit
            });
        }
    }, [onChange, unit, units, number]);

    const handleUnitButtonWheel = useCallback((event: ReactWheelEvent) => {
        if (document.activeElement === actualInputRef.current && unit && units) {
            const delta = event.deltaY < 0 ? -1 : 1;
            const nextIndex = (units.indexOf(unit) + units.length + delta) % units.length;
            const newUnit = units[nextIndex];
            onChange?.({
                value: stringifyNumber(number, newUnit),
                number,
                unit: newUnit
            });
            event.stopPropagation();
        }
    }, [onChange, unit, units, number]);

    const handleArrowUp = useCallback(() => {
        if (document.activeElement === actualInputRef.current) {
            const newNumber = parseNumber(number === null ? 0 : number + step, {min, max, step});
            onChange?.({
                value: stringifyNumber(newNumber, unit),
                number: newNumber,
                unit
            });
        }
    }, [onChange, unit, min, max, step, number]);

    const handleArrowDown = useCallback(() => {
        if (document.activeElement === actualInputRef.current) {
            const newNumber = parseNumber(number === null ? defaultValue : number - step, {min, max, step});
            onChange?.({
                value: stringifyNumber(newNumber, unit),
                number: newNumber,
                unit
            });
        }
    }, [onChange, unit, min, max, step, number]);

    useEffect(() => {
        setLocalNumber(number);
    }, [number]);

    useDocumentEvent('wheel', handleDocumentWheel);
    useHotkey('ArrowUp', handleArrowUp);
    useHotkey('ArrowDown', handleArrowDown);

    return (
        <Input className={mergeClassNames(css['numberField'], className)}
               value={localNumber === null ? '' : localNumber.toString()}
               onChange={handleInputChange}
               onBlur={handleInputBlur}
               inputRef={actualInputRef}
               placeholder={placeholder}
               clearable={clearable}
               disabled={disabled}
               invalid={!isLocalNumberValid}>
            {unit && number !== null &&
                <button className={css['unitButton']}
                        onClick={handleUnitButtonClick}
                        onWheel={handleUnitButtonWheel}
                        disabled={disabled || !units || units.length <= 1}>
                    {unit}
                </button>
            }
            <SliderButton className={css['sliderButton']}
                          value={number ?? parseNumber(defaultValue, {min, max, step})}
                          min={min}
                          max={max}
                          step={step}
                          disabled={disabled}
                          onChange={handleSliderButtonChange}/>
        </Input>
    );

}

const DEFAULT_UNIT_STEP_MAP: Record<string, number> = {
    '%': 1,
    'px': 1,
    'pt': 1,
    'vw': 1,
    'vh': 1,
    'deg': 1,
    'em': 0.1,
    'rem': 0.1,
    'fr': 1
};

export function getDefaultUnitStep(unit: string | null): number {
    return (unit ? DEFAULT_UNIT_STEP_MAP[unit] : null) ?? 1;
}