import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {mergeClassNames, useRerender} from '@webaker/package-utils';
import {useEffect, useRef, useState} from 'react';
import {LoadingBarCSS} from './loading-bar-css';
import {LoadingService} from './services/loading-service';

export interface LoadingBarProps {

}

export interface LoadingBarDeps {
    loadingService: LoadingService;
}

export function LoadingBar({}: LoadingBarProps) {

    const rerender = useRerender();
    const css = useThemedCSS(LoadingBarCSS);
    const loadingService = useDependency<LoadingBarDeps>()('loadingService');
    const [isActive, setIsActive] = useState(false);
    const [hasAnimation, setHasAnimation] = useState(false);
    const [progress, setProgress] = useState(0);
    const isLoading = loadingService.isLoading();
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        loadingService.watch(rerender);
        return () => {
            loadingService.unwatch(rerender);
        };
    }, []);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        if (isLoading) {
            setProgress(0);
            setIsActive(true);
            setHasAnimation(false);
            intervalRef.current = setInterval(() => {
                setHasAnimation(true);
                setProgress((progress: number): number => {
                    return progress + ((1 - progress) / 2) / 10;
                });
            }, 100);
        } else {
            setProgress(1);
            setIsActive(false);
        }
    }, [isLoading]);

    return (
        <div className={mergeClassNames(
            css['loadingBar'],
            isActive && css['is-active'],
            hasAnimation && css['has-animation']
        )}>
            <div className={css['progressBar']}
                 style={{width: `${progress * 100}%`}}/>
        </div>
    );

}