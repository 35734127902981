import {HTMLTemplate} from './html-template';
import {HTMLTemplateInjector} from './html-template-injector';

export interface HTMLTemplateRegistry {
    registerInjector: (htmlTemplateInjector: HTMLTemplateInjector) => void;
    runInjectors: (htmlTemplate: HTMLTemplate) => Promise<void>;
}

export function createHTMLTemplateRegistry(): HTMLTemplateRegistry {

    const htmlTemplateInjectors: HTMLTemplateInjector[] = [];

    const registerInjector = (htmlTemplateInjector: HTMLTemplateInjector) => {
        htmlTemplateInjectors.push(htmlTemplateInjector);
    };

    const runInjectors = async (htmlTemplate: HTMLTemplate): Promise<void> => {
        await Promise.all(htmlTemplateInjectors.map(async (injector: HTMLTemplateInjector) => {
            await injector.inject(htmlTemplate);
        }));
    };

    return {
        registerInjector,
        runInjectors
    };

}