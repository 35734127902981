import {ComponentValidationRules} from '@webaker/app';
import {isEnum, optional} from '@webaker/package-validation';
import {HIDDEN_EVERYWHERE, HIDDEN_ON_DESKTOP, HIDDEN_ON_MOBILE, HiddenComponent} from './hidden-component';

export function createHiddenComponentValidationRules(): ComponentValidationRules<HiddenComponent> {
    return {
        hidden: optional(isEnum({
            values: [
                HIDDEN_EVERYWHERE,
                HIDDEN_ON_MOBILE,
                HIDDEN_ON_DESKTOP
            ]
        }))
    };
}