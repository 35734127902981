import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {CardSize} from './card';

export type CardClassName = 'card' | 'header' | 'title' | 'subtitle' | 'content' | `size-${CardSize}`;

export function CardCSS({theme}: CSSThemeProps) {
    return css<CardClassName>`

        .card {
            width: calc(100% - ${theme.spacing.large} * 2);
            margin: ${theme.spacing.large} auto ${theme.spacing.veryLarge};
        }

        .size-narrow {
            max-width: 800px;
        }

        .size-wide {
            max-width: 1200px;
        }

        .size-full {
            max-width: 100%;
        }

        .header {
            position: relative;
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${theme.spacing.medium};
            width: 100%;
            height: 2em;
            color: ${theme.color.black};
            font-size: ${theme.fontSize.large};
            font-weight: bold;
            text-overflow: ellipsis;
            text-shadow: ${theme.textShadow.large};
            overflow: hidden;
        }

        .subtitle {
            color: ${theme.color.gray};
            font-weight: normal;
            margin-top: ${theme.spacing.medium};
        }

        .content {
            width: 100%;
            padding: ${theme.spacing.large};
            background: ${theme.color.white};
            box-shadow: ${theme.boxShadow.large};
            border-radius: ${theme.borderRadius.large};
        }

    `;
}