import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {ExtensionApi} from '../extension-api';
import {createExtensionClientApi} from './extension-client-api';
import {ExtensionClientManager} from './extension-client-manager';

export interface ProvidedExtensionClientDeps {
    extensionApi: ExtensionApi;
}

export interface RequiredExtensionClientDeps {
    apiClientFactory: ApiClientFactory;
    extensionManager: ExtensionClientManager;
}

export interface ExtensionClientDeps extends ProvidedExtensionClientDeps, RequiredExtensionClientDeps {

}

export interface ExtensionClientConfig {

}

export type ExtensionClientProvider = Provider<ExtensionClientDeps, ExtensionClientConfig>;

export function createExtensionClientProvider(): ExtensionClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('extensionApi', ({resolve}) => {
                return createExtensionClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const extensionManager = resolve('extensionManager');
            const extensionApi = resolve('extensionApi');
            extensionManager.setApi(extensionApi);
        }

    };
}