import {EditorSettingsOptions} from '@webaker/editor';
import {MdIcon} from '@webaker/package-ui';
import {WebsiteSettingsView} from './website-settings-view';

export const WEBSITE_NAME_SETTING = 'websiteName';
export const WEBSITE_AUTHOR_SETTING = 'websiteAuthor';
export const LANGUAGE_SETTING = 'language';
export const WEBSITE_ICON_ID_SETTING = 'websiteIconId';

export const WEBSITE_SETTINGS_NAME = 'Settings';
export const WEBSITE_SETTINGS_ORDER = 100;

export interface WebsiteSettingsOptions extends EditorSettingsOptions {

}

export function createWebsiteSettingsOptions(): WebsiteSettingsOptions {
    return {
        name: WEBSITE_SETTINGS_NAME,
        icon: <MdIcon name="settings"/>,
        view: WebsiteSettingsView,
        order: WEBSITE_SETTINGS_ORDER
    };
}