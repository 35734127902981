import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useSafeHotkey} from '@webaker/package-utils';
import {ReactNode, useEffect, useRef} from 'react';
import {ModalCSS} from './modal-css';
import {Overlay} from './overlay';

export interface ModalProps {
    title?: string;
    width?: string;
    children?: ReactNode;
    onEscape?: () => void;
    onEnter?: () => void;
    className?: string;
}

const FOCUS_SELECTOR = 'input, textarea';
const FOCUS_TIMEOUT_MS = 100;

export function Modal({title, width, children, onEscape, onEnter, className}: ModalProps) {

    const css = useThemedCSS(ModalCSS);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useSafeHotkey('Escape', () => {
        onEscape?.();
    }, [onEscape]);

    useSafeHotkey('Enter', () => {
        onEnter?.();
    }, [onEnter]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const focusableElement = modalRef.current?.querySelector(FOCUS_SELECTOR) as HTMLElement | undefined;
            const activeElement = document.activeElement as HTMLElement | undefined;
            console.log(modalRef.current, focusableElement, activeElement);
            if (focusableElement) {
                focusableElement.focus();
            } else if (activeElement) {
                activeElement.blur();
            }
        }, FOCUS_TIMEOUT_MS);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <Overlay>
            <div ref={modalRef}
                 className={mergeClassNames(css['modal'], className)}
                 style={{width}}>
                {title && (
                    <header className={css['title']}>
                        {title}
                    </header>
                )}
                {children}
            </div>
        </Overlay>
    );

}