import {Component} from '@webaker/app';

export interface HiddenComponent extends Component {
    hidden?: HiddenComponentMode;
}

export const HIDDEN_EVERYWHERE = 'everywhere';
export const HIDDEN_ON_MOBILE = 'onMobile';
export const HIDDEN_ON_DESKTOP = 'onDesktop';

export type HiddenComponentMode = typeof HIDDEN_EVERYWHERE | typeof HIDDEN_ON_MOBILE | typeof HIDDEN_ON_DESKTOP;