export function mergeDeep<T extends {}>(target: T, ...sources: Array<Partial<T>>): T {
    return sources.reduce<T>((result: T, source: Partial<T>): T => {
        if (isObject(source)) {
            Object.keys(source).forEach((key: string): void => {
                const resultProp = result[key as keyof T];
                const sourceProp = source[key as keyof T];
                if (isObject(resultProp) && isObject(sourceProp)) {
                    result[key as keyof T] = mergeDeep(resultProp, sourceProp);
                } else if (key in source) {
                    result[key as keyof T] = sourceProp!;
                }
            });
        }
        return result;
    }, {...target});
}

function isObject(item: unknown): item is {} {
    return !!item && typeof item === 'object';
}