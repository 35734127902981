import {CSSThemeStore} from '@webaker/package-css-theme';
import {Provider} from '@webaker/package-deps';
import {ThemeRenderer} from '@webaker/package-theme';
import {createMobileThemeRenderer, MobileThemeRenderer} from '../mobile-theme-renderer';
import {MOBILE_THEME_VARIANT} from '../mobile-theme-variant';

export interface ProvidedMobileThemeClientDeps {
    mobileThemeRenderer: MobileThemeRenderer;
}

export interface RequiredMobileThemeClientDeps {
    cssThemeStore: CSSThemeStore;
    themeRenderer: ThemeRenderer;
}

export interface MobileThemeClientDeps extends ProvidedMobileThemeClientDeps, RequiredMobileThemeClientDeps {

}

export interface MobileThemeClientConfig {

}

export type MobileThemeClientProvider = Provider<MobileThemeClientDeps, MobileThemeClientConfig>;

export function createMobileThemeClientProvider(): MobileThemeClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('mobileThemeRenderer', ({resolve}) => {
                return createMobileThemeRenderer({
                    cssThemeStore: resolve('cssThemeStore')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const themeRenderer = resolve('themeRenderer');
            const mobileThemeRenderer = resolve('mobileThemeRenderer');
            themeRenderer.registerRenderer(MOBILE_THEME_VARIANT, mobileThemeRenderer);
        }

    };
}