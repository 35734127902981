import {compile as stylisCompile, Element, RULESET, serialize as stylisSerialize, stringify as stylisStringify} from 'stylis';
import {ClassName} from './class-name/class-name';
import {ClassNamesMap} from './class-name/class-names-map';
import {CSS} from './css';
import {cssHash} from './css-hash';
import {CSSOptions} from './css-options';
import {CSSValue} from './css-value';

export interface CSSFactoryParams {
    strings: string[];
    values: CSSValue[];
    options: CSSOptions;
}

const CSS_ID_PREFIX = 'css';
const CSS_CLASS_PREFIX = '.';
const CSS_CLASS_REGEXP = /\.[\w-]+/g;

export function createCSS<C extends ClassName = ClassName>({strings, values, options}: CSSFactoryParams): CSS<C> {
    const originalCSS = joinStringsAndValues(strings, values);
    const elements = stylisCompile(originalCSS);
    const name = findFirstClassNameInElements(elements);
    const hash = cssHash(originalCSS, {length: options.hashLength});
    const css = {} as CSS<C>;
    css.id = name ? `${CSS_ID_PREFIX}-${hash}-${name}` : `${CSS_ID_PREFIX}-${hash}`;
    css.cssText = stringifyElements(elements, hash, options);
    css.classNames = collectClassNamesFromElements(elements, hash, options);
    css.attach = createAttachFunction(css);
    css.detach = createDetachFunction(css);
    return css;
}

function joinStringsAndValues(strings: string[], values: CSSValue[]): string {
    return strings.reduce((result: string, string: string, index: number) => {
        if (index < values.length) {
            const value = values[index];
            return `${result}${string}${value}`;
        }
        return `${result}${string}`;
    }, '');
}

function generateClassName(className: ClassName, hash: string, options: CSSOptions): ClassName {
    if (options.minify) {
        return `${cssHash(className + hash, {length: options.hashLength})}`;
    }
    return `${hash}-${className}`;
}

function generateClassNamesMap(classNames: ClassName[], hash: string, options: CSSOptions): ClassNamesMap {
    return classNames.reduce((classNames: ClassNamesMap, className: ClassName): ClassNamesMap => {
        return {
            ...classNames,
            [className]: generateClassName(className, hash, options)
        };
    }, {} as ClassNamesMap);
}

function findFirstClassNameInElements(elements: Element[]): ClassName | null {
    for (const element of elements) {
        if (element.type === RULESET) {
            const classNames = findClassNamesInSelector(element.value);
            if (classNames.length) {
                return classNames[0];
            }
        }
    }
    return null;
}

function findClassNamesInSelector(selector: string): ClassName[] {
    return selector.match(CSS_CLASS_REGEXP)?.map((classSelector: string): string => {
        return classSelector.replace(CSS_CLASS_PREFIX, '');
    }) ?? [];
}

function collectClassNamesFromElements<C extends ClassName = ClassName>(elements: Element[], hash: string, options: CSSOptions): ClassNamesMap<C> {
    return elements.reduce((classNames: ClassNamesMap<C>, element: Element): ClassNamesMap<C> => {
        if (element.type === RULESET) {
            return {
                ...classNames,
                ...generateClassNamesMap(findClassNamesInSelector(element.value), hash, options),
                ...collectClassNamesFromElements(element.children as Element[], hash, options)
            };
        }
        return classNames;
    }, {} as ClassNamesMap<C>) as ClassNamesMap<C>;
}

function modifyElementsValues(elements: Element[], modifier: (value: string, type: Element['type']) => string): Element[] {
    return elements.map((element: Element): Element | null => {
        return {
            ...element,
            value: modifier(element.value, element.type),
            props: Array.isArray(element.props)
                ? element.props.map(prop => modifier(prop, element.type))
                : modifier(element.props, element.type),
            children: Array.isArray(element.children)
                ? modifyElementsValues(element.children, modifier)
                : modifier(element.children, element.type)
        };
    }).filter(Boolean) as Element[];
}

function stringifyElements(elements: Element[], hash: string, options: CSSOptions): string {
    return stylisSerialize(modifyElementsValues(elements, (value: string, type: Element['type']): string => {
        if (type === RULESET) {
            value = value.replace(CSS_CLASS_REGEXP, (selector: string): string => {
                return CSS_CLASS_PREFIX + generateClassName(selector.replace(CSS_CLASS_PREFIX, ''), hash, options);
            });
        }
        return value;
    }), stylisStringify);
}

function createAttachFunction(css: CSS): () => void {
    return typeof window !== 'undefined' ? () => {
        const cssInstances = getAttachedCSSInstancesById(css.id);
        addAttachedCSSInstance(css);
        if (cssInstances.length === 0) {
            const style = document.getElementById(css.id);
            if (!css.cssText) {
                style?.remove();
            } else if (style) {
                if (style.innerHTML !== css.cssText) {
                    style.innerHTML = css.cssText;
                }
            } else {
                const newStyle = document.createElement('style');
                newStyle.id = css.id;
                newStyle.innerHTML = css.cssText;
                document.head.appendChild(newStyle);
            }
        }
    } : () => {
    };
}

function createDetachFunction(css: CSS): () => void {
    return typeof window !== 'undefined' ? () => {
        removeAttachedCSSInstance(css);
        const cssInstances = getAttachedCSSInstancesById(css.id);
        if (cssInstances.length === 0) {
            document.getElementById(css.id)?.remove();
        }
    } : () => {
    };
}

const attachedCSSInstancesMap: Map<CSS['id'], Set<CSS>> = new Map();

function getAttachedCSSInstancesById(id: string): CSS[] {
    return [...attachedCSSInstancesMap.get(id) ?? []];
}

function addAttachedCSSInstance(css: CSS): void {
    const cssInstances = attachedCSSInstancesMap.get(css.id) ?? new Set();
    cssInstances.add(css);
    attachedCSSInstancesMap.set(css.id, cssInstances);
}

function removeAttachedCSSInstance(css: CSS): void {
    const cssInstances = attachedCSSInstancesMap.get(css.id) ?? new Set();
    cssInstances.delete(css);
    attachedCSSInstancesMap.set(css.id, cssInstances);
}