export type Color = HexColor | RGBColor | HSLColor;
export type ColorFormat = 'hex' | 'rgb' | 'hsl';

export interface ColorOptions {
    format?: ColorFormat;
    red?: ColorModifier;
    green?: ColorModifier;
    blue?: ColorModifier;
    alpha?: ColorModifier;
    hue?: ColorModifier;
    saturation?: ColorModifier;
    lightness?: ColorModifier;
}

interface ContrastOptions {
    threshold?: Number_0_100;
    dark?: Color;
    light?: Color;
}

export interface ParsedColor extends ParsedRGBColor, ParsedHSLColor {

}

interface ParsedRGBColor {
    red: Number_0_255;
    green: Number_0_255;
    blue: Number_0_255;
    alpha: Number_0_1;
}

interface ParsedHSLColor {
    hue: Number_0_360;
    saturation: Number_0_100;
    lightness: Number_0_100;
    alpha: Number_0_1;
}

type HexColor = `#${string}`;
type RGBColor = `rgb(${string})` | `rgba(${string})`;
type HSLColor = `hsl(${string})` | `hsla(${string})`;
type ColorChannel = keyof ParsedColor;
type ColorModifier = number | `${number}` | `${MathOperator}${number}`;
type MathOperator = '=' | '+' | '-' | '*' | '/';
type Number_0_1 = number;
type Number_0_100 = number;
type Number_0_255 = number;
type Number_0_360 = number;

const HEX_COLOR_REGEXP = /^#([0-9A-F]{3,4}|[0-9A-F]{6}|[0-9A-F]{8})$/i;
const RGB_COLOR_REGEXP = /^rgba?\((?<r>\d{1,3})(,\s*|\s+)(?<g>\d{1,3})(,\s*|\s+)(?<b>\d{1,3})((,\s*|\s+)(?<a>[01](\.\d{1,10}))?)?\)$/i;
const HSL_COLOR_REGEXP = /^hsla?\((?<h>\d{1,3})(deg)?(,\s*|\s+)(?<s>\d{1,3})%?(,\s*|\s+)(?<l>\d{1,3})%?((,\s*|\s+)(?<a>[01](\.\d{1,10})?))?\)$/i;
const COLOR_MODIFIER_REGEXP = /^(?<operator>[=+\-*/])?(?<value>-?\d+(\.\d+)?)$/;

const DEFAULT_CONTRAST_THRESHOLD = 75;
const DEFAULT_CONTRAST_DARK = '#000';
const DEFAULT_CONTRAST_LIGHT = '#fff';

export function isColor(color: string): color is Color {
    return getColorFormat(color) !== null;
}

export function adjustColor(color: Color, options: ColorOptions = {}): Color {
    const parsedColor = adjustParsedColor(parseColor(color), options);
    const format = options.format ?? getColorFormat(color)!;
    return stringifyParsedColor(parsedColor, format);
}

export function contrastColor(color: Color, {
    threshold = DEFAULT_CONTRAST_THRESHOLD,
    dark = DEFAULT_CONTRAST_DARK,
    light = DEFAULT_CONTRAST_LIGHT
}: ContrastOptions = {}): Color {
    return parseColor(color).lightness < threshold ? light : dark;
}

export const color = adjustColor;
export const contrast = contrastColor;

export function parseColor(color: Color): ParsedColor {
    const format = getColorFormat(color);
    if (format === 'hex') {
        const values = color.slice(1);
        return parseRGBColor({
            red: normalize(values.length < 6 ? parseInt(values.charAt(0), 16) / 15 * 255 : parseInt(values.slice(0, 2), 16), 'red'),
            green: normalize(values.length < 6 ? parseInt(values.charAt(1), 16) / 15 * 255 : parseInt(values.slice(2, 4), 16), 'green'),
            blue: normalize(values.length < 6 ? parseInt(values.charAt(2), 16) / 15 * 255 : parseInt(values.slice(4, 6), 16), 'blue'),
            alpha: normalize(values.length === 4 ? parseInt(values.charAt(3), 16) / 15 : values.length === 8 ? parseInt(values.slice(6, 8), 16) / 255 : 1, 'alpha')
        });
    }
    if (format === 'rgb') {
        const {r, g, b, a} = color.match(RGB_COLOR_REGEXP)!.groups as any;
        return parseRGBColor({
            red: normalize(parseInt(r), 'red'),
            green: normalize(parseInt(g), 'green'),
            blue: normalize(parseInt(b), 'blue'),
            alpha: normalize(!isNaN(a) ? parseFloat(a) : 1, 'alpha')
        });
    }
    if (format === 'hsl') {
        const {h, s, l, a} = color.match(HSL_COLOR_REGEXP)!.groups as any;
        return parseHSLColor({
            hue: normalize(parseInt(h), 'hue'),
            saturation: normalize(parseInt(s), 'saturation'),
            lightness: normalize(parseInt(l), 'lightness'),
            alpha: normalize(!isNaN(a) ? parseFloat(a) : 1, 'alpha')
        });
    }
    throw new Error(`Invalid color [${color}]`);
}

export function stringifyParsedColor(parsedColor: ParsedColor, format: ColorFormat): Color {
    if (format === 'hex') {
        const r = parsedColor.red.toString(16).padStart(2, '0');
        const g = parsedColor.green.toString(16).padStart(2, '0');
        const b = parsedColor.blue.toString(16).padStart(2, '0');
        const a = Math.round(parsedColor.alpha * 255).toString(16).padStart(2, '0');
        return r[0] === r[1] && g[0] === g[1] && b[0] === b[1] && a[0] === a[1]
            ? a[0] === 'f' ? `#${r[0]}${g[0]}${b[0]}` : `#${r[0]}${g[0]}${b[0]}${a[0]}`
            : a === 'ff' ? `#${r}${g}${b}` : `#${r}${g}${b}${a}`;
    }
    if (format === 'rgb') {
        const r = parsedColor.red;
        const g = parsedColor.green;
        const b = parsedColor.blue;
        const a = parsedColor.alpha;
        return a === 1 ? `rgb(${r},${g},${b})` : `rgba(${r},${g},${b},${a})`;
    }
    if (format === 'hsl') {
        const h = parsedColor.hue;
        const s = parsedColor.saturation;
        const l = parsedColor.lightness;
        const a = parsedColor.alpha;
        return a === 1 ? `hsl(${h},${s}%,${l}%)` : `hsla(${h},${s}%,${l}%,${a})`;
    }
    throw new Error(`Invalid color format [${format}]`);
}

export function adjustParsedColor(parsedColor: ParsedColor, options: ColorOptions = {}): ParsedColor {
    let newParsedColor = {...parsedColor};
    for (const key in options) {
        if (key !== 'format') {
            const channel: ColorChannel = key as ColorChannel;
            const match = options[channel]?.toString().match(COLOR_MODIFIER_REGEXP) as any;
            if (!match) {
                throw new Error(`Invalid color modifier [${key} = ${options[channel]}]`);
            }
            const operator: MathOperator = match.groups.operator ?? '=';
            const value: number = parseFloat(match.groups.value);
            if (operator === '=') {
                newParsedColor[channel] = normalize(value, channel);
            } else if (operator === '+') {
                newParsedColor[channel] = normalize(newParsedColor[channel] + value, channel);
            } else if (operator === '-') {
                newParsedColor[channel] = normalize(newParsedColor[channel] - value, channel);
            } else if (operator === '*') {
                newParsedColor[channel] = normalize(newParsedColor[channel] * value, channel);
            } else if (operator === '/') {
                newParsedColor[channel] = normalize(newParsedColor[channel] / value, channel);
            }
            if (channel === 'red' || channel === 'green' || channel === 'blue') {
                newParsedColor = parseRGBColor(newParsedColor);
            }
            if (channel === 'hue' || channel === 'saturation' || channel === 'lightness') {
                newParsedColor = parseHSLColor(newParsedColor);
            }
        }
    }
    return newParsedColor;
}

function parseRGBColor(color: ParsedRGBColor): ParsedColor {
    const r = color.red / 255;
    const g = color.green / 255;
    const b = color.blue / 255;
    const m = Math.max(r, g, b);
    const n = Math.min(r, g, b);
    const d = m - n;
    const hue = d === 0 ? 0 : m === r ? 60 * (((g - b) / d) % 6) : m === g ? 60 * ((b - r) / d + 2) : m === b ? 60 * ((r - g) / d + 4) : 0;
    const lightness = (m + n) / 2 * 100;
    const saturation = d === 0 ? 0 : d / (1 - Math.abs(2 * lightness / 100 - 1)) * 100;
    return {
        ...color,
        hue: normalize(hue, 'hue'),
        saturation: normalize(saturation, 'saturation'),
        lightness: normalize(lightness, 'lightness')
    };
}

function parseHSLColor(color: ParsedHSLColor): ParsedColor {
    const h = color.hue / 360;
    const s = color.saturation / 100;
    const l = color.lightness / 100;
    if (s === 0) {
        return {
            ...color,
            red: normalize(255 * l, 'red'),
            green: normalize(255 * l, 'green'),
            blue: normalize(255 * l, 'blue')
        };
    }
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h * 6) % 2 - 1));
    const m = l - c / 2;
    const red = 255 * (m + (h < 1 / 6 ? c : h < 2 / 6 ? x : h < 3 / 6 ? 0 : h < 4 / 6 ? 0 : h < 5 / 6 ? x : c));
    const green = 255 * (m + (h < 1 / 6 ? x : h < 2 / 6 ? c : h < 3 / 6 ? c : h < 4 / 6 ? x : h < 5 / 6 ? 0 : 0));
    const blue = 255 * (m + (h < 1 / 6 ? 0 : h < 2 / 6 ? 0 : h < 3 / 6 ? x : h < 4 / 6 ? c : h < 5 / 6 ? c : x));
    return {
        ...color,
        red: normalize(red, 'red'),
        green: normalize(green, 'green'),
        blue: normalize(blue, 'blue')
    };
}

function getColorFormat(color: string): ColorFormat | null {
    if (HEX_COLOR_REGEXP.test(color)) {
        return 'hex';
    }
    if (RGB_COLOR_REGEXP.test(color)) {
        return 'rgb';
    }
    if (HSL_COLOR_REGEXP.test(color)) {
        return 'hsl';
    }
    return null;
}

function normalize(n: number, channel: ColorChannel): number {
    if (channel === 'alpha') {
        return Math.round((n < 0 ? 0 : n > 1 ? 1 : n) * 1000) / 1000;
    }
    if (channel === 'red' || channel === 'green' || channel === 'blue') {
        return Math.round(n < 0 ? 0 : n > 255 ? 255 : n);
    }
    if (channel === 'hue') {
        return Math.round(n < 0 ? n % 360 + 360 : n > 360 ? n % 360 : n);
    }
    if (channel === 'saturation' || channel === 'lightness') {
        return Math.round(n < 0 ? 0 : n > 100 ? 100 : n);
    }
    throw new Error(`Invalid color channel [${channel}]`);
}