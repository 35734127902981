import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type TextFieldClassName = 'textField';

export function TextFieldCSS({}: CSSThemeProps) {
    return css<TextFieldClassName>`

        .textField {
            position: relative;
        }

    `;
}