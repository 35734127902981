export interface ParseNumberOptions {
    min?: number;
    max?: number;
    step?: number;
    default?: number;
}

const PRECISION = 12;

export function parseNumber(value: number | string, {
    min = -Infinity,
    max = Infinity,
    step = 0,
    default: defaultValue = 0
}: ParseNumberOptions = {}): number {
    const number = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(number)) {
        return Math.max(min, Math.min(max, defaultValue));
    }
    const roundedNumber = step === 0 ? number : parseFloat((Math.round(number / step) * step).toPrecision(PRECISION));
    return Math.max(min, Math.min(max, roundedNumber));
}

export interface ValidateNumberOptions {
    min?: number;
    max?: number;
    step?: number;
}

export function validateNumber(value: number | string, {
    min = -Infinity,
    max = Infinity,
    step = 0
}: ValidateNumberOptions = {}): boolean {
    const number = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(number)) {
        return false;
    }
    const roundedNumber = step === 0 ? number : parseFloat((Math.round(number / step) * step).toPrecision(PRECISION));
    const correctNumber = Math.max(min, Math.min(max, roundedNumber));
    return number === correctNumber;
}