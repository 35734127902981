import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {ColumnAlign} from './column';

export type ColumnClassName = 'column';

export function ColumnCSS({}: CSSThemeProps) {
    return css<ColumnClassName>`

        .column {
            display: grid;
            grid-auto-rows: 1fr;
            grid-auto-flow: row;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    `;
}

export interface ColumnPrivateCSSProps extends CSSThemeProps {
    rows: string[];
    align: ColumnAlign;
    margin: CSSThemeSize;
    gap: CSSThemeSize;
}

export function ColumnPrivateCSS({theme, rows, align, margin, gap}: ColumnPrivateCSSProps) {
    return css<ColumnClassName>`

        .column {
            align-items: ${mapColumnAlign(align)};
            grid-gap: ${theme.spacing[gap!]};
            margin: ${theme.spacing[margin!]} 0;
            grid-template-rows: ${rows.join(' ')};
        }

    `;
}

function mapColumnAlign(align: ColumnAlign): string {
    return {
        start: 'flex-start',
        center: 'center',
        end: 'flex-end'
    }[align];
}