import {useDependency} from '@webaker/package-deps';
import {FileStore} from '@webaker/package-file';
import {IMAGE_FILE_TYPE} from '@webaker/package-image-file';
import {SettingsApi, SettingsStore} from '@webaker/package-settings';
import {useStore} from '@webaker/package-store';
import {Button, ButtonGroup, FilePicker, FilePickerChangeEvent, Label, MdIcon, Panel, Row, SelectBox, SelectBoxChangeEvent, SelectBoxOption, TextField, TextFieldChangeEvent, useToast} from '@webaker/package-ui';
import {LANGUAGE_CODES} from '@webaker/package-utils';
import {useCallback, useMemo, useState} from 'react';
import {WebsiteSettingsMap} from './website-settings-map';
import {LANGUAGE_SETTING, WEBSITE_AUTHOR_SETTING, WEBSITE_ICON_ID_SETTING, WEBSITE_NAME_SETTING} from './website-settings-options';

export interface WebsiteSettingsViewProps {

}

export interface WebsiteSettingsViewDeps {
    fileStore: FileStore;
    settingsApi: SettingsApi;
    settingsStore: SettingsStore;
}

export function WebsiteSettingsView({}: WebsiteSettingsViewProps) {

    const fileStore = useStore(useDependency<WebsiteSettingsViewDeps>()('fileStore'));
    const settingsApi = useDependency<WebsiteSettingsViewDeps>()('settingsApi');
    const settingsStore = useStore(useDependency<WebsiteSettingsViewDeps>()('settingsStore'));
    const websiteName = settingsStore.getValue<WebsiteSettingsMap, typeof WEBSITE_NAME_SETTING>(WEBSITE_NAME_SETTING);
    const websiteAuthor = settingsStore.getValue<WebsiteSettingsMap, typeof WEBSITE_AUTHOR_SETTING>(WEBSITE_AUTHOR_SETTING);
    const languageSetting = settingsStore.getValue<WebsiteSettingsMap, typeof LANGUAGE_SETTING>(LANGUAGE_SETTING);
    const websiteIconId = settingsStore.getValue<WebsiteSettingsMap, typeof WEBSITE_ICON_ID_SETTING>(WEBSITE_ICON_ID_SETTING);
    const file = websiteIconId ? fileStore.getFileById(websiteIconId) : null;
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const toast = useToast();

    const languageOptions = useMemo<SelectBoxOption<string>[]>(() => {
        return Object.entries(LANGUAGE_CODES).map(([code, name]) => ({
            value: code,
            label: name
        }));
    }, []);

    const updateWebsiteName = useCallback((event: TextFieldChangeEvent) => {
        settingsStore.setValue<WebsiteSettingsMap, typeof WEBSITE_NAME_SETTING>(WEBSITE_NAME_SETTING, event.value);
    }, []);

    const updateWebsiteAuthor = useCallback((event: TextFieldChangeEvent) => {
        settingsStore.setValue<WebsiteSettingsMap, typeof WEBSITE_AUTHOR_SETTING>(WEBSITE_AUTHOR_SETTING, event.value);
    }, []);

    const updateLanguage = useCallback((event: SelectBoxChangeEvent) => {
        settingsStore.setValue<WebsiteSettingsMap, typeof LANGUAGE_SETTING>(LANGUAGE_SETTING, event.value);
    }, []);

    const updateWebsiteIcon = useCallback((event: FilePickerChangeEvent) => {
        if (event.file) {
            fileStore.addFile(event.file);
            settingsStore.setValue<WebsiteSettingsMap, typeof WEBSITE_ICON_ID_SETTING>(WEBSITE_ICON_ID_SETTING, event.file.id);
        } else {
            settingsStore.setValue<WebsiteSettingsMap, typeof WEBSITE_ICON_ID_SETTING>(WEBSITE_ICON_ID_SETTING, null);
        }
    }, []);

    const saveWebsiteSettings = useCallback(async () => {
        setIsSaving(true);
        await settingsApi.saveSettingsMap({
            [WEBSITE_NAME_SETTING]: websiteName,
            [WEBSITE_AUTHOR_SETTING]: websiteAuthor,
            [LANGUAGE_SETTING]: languageSetting,
            [WEBSITE_ICON_ID_SETTING]: websiteIconId
        });
        setIsSaving(false);
        toast('Website settings saved');
    }, [websiteName, websiteAuthor, languageSetting, websiteIconId]);

    return (
        <Panel title="Website">
            <Row columns={['1fr', '2fr']}>
                <Label>
                    Website name
                </Label>
                <TextField value={websiteName}
                           onChange={updateWebsiteName}/>
            </Row>

            <Row columns={['1fr', '2fr']}>
                <Label>
                    Website author
                </Label>
                <TextField value={websiteAuthor}
                           onChange={updateWebsiteAuthor}/>
            </Row>

            <Row columns={['1fr', '2fr']}>
                <Label>
                    Language
                </Label>
                <SelectBox value={languageSetting}
                           options={languageOptions}
                           onChange={updateLanguage}
                           clearable={true}/>
            </Row>

            <Row columns={['1fr', '2fr']}>
                <Label>
                    Icon
                </Label>
                <FilePicker file={file}
                            publish={true}
                            onChange={updateWebsiteIcon}
                            types={[IMAGE_FILE_TYPE]}/>
            </Row>
            <ButtonGroup align="end">
                <Button color="success"
                        style="solid"
                        icon={<MdIcon name="save"/>}
                        loading={isSaving}
                        onClick={saveWebsiteSettings}>
                    Save
                </Button>
            </ButtonGroup>
        </Panel>
    );
}