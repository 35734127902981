import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type LoadingIndicatorClassName = 'loadingIndicator';

export function LoadingIndicatorCSS({theme}: CSSThemeProps) {
    return css<LoadingIndicatorClassName>`

        .loadingIndicator {
            animation: spin ${theme.transition.short} linear infinite;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }

    `;
}