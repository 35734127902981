import {isArray, isEmail, isId, isObject, isPassword, isString, optional, ValidationErrorsBag, Validator} from '@webaker/package-validation';
import {User} from './user';

export interface UserValidator {
    validateUser: (item: unknown, options?: UserRuleOptions) => item is User;
    assertUser: (item: unknown, options?: UserRuleOptions) => asserts item is User;
    getUserErrors: (item: unknown, options?: UserRuleOptions) => ValidationErrorsBag;
}

export interface UserValidatorDeps {
    validator: Validator;
}

export interface UserValidatorConfig {
    passwordMinLength: number;
    passwordStrictPolicy: boolean;
}

export interface UserRuleOptions {
    partial?: boolean;
    requirePassword?: boolean;
}

export function createUserValidator({
    validator
}: UserValidatorDeps, {
    passwordMinLength,
    passwordStrictPolicy
}: UserValidatorConfig): UserValidator {

    // fix for TS2775
    const baseValidator: Validator = validator;

    const validateUser = (item: unknown, options: UserRuleOptions = {}): item is User => {
        return baseValidator.validate(item, isUser(options));
    };

    const assertUser = (item: unknown, options: UserRuleOptions = {}): asserts item is User => {
        baseValidator.assert(item, isUser(options));
    };

    const getUserErrors = (item: unknown, options: UserRuleOptions = {}): ValidationErrorsBag => {
        return baseValidator.errors(item, isUser(options));
    };

    const isUser = ({partial, requirePassword}: UserRuleOptions = {}) => {
        const isUserPassword = isPassword({
            minLength: passwordMinLength,
            requireLowercaseLetter: passwordStrictPolicy,
            requireUppercaseLetter: passwordStrictPolicy,
            requireNumber: passwordStrictPolicy,
            requireSpecialCharacter: passwordStrictPolicy
        });
        return isObject<User>({
            properties: {
                id: isId(),
                rolesIds: isArray({
                    rule: isString()
                }),
                name: isString({minLength: 1}),
                email: isEmail(),
                password: requirePassword ? isUserPassword : optional(isUserPassword)
            },
            partial,
        });
    };

    return {
        validateUser,
        assertUser,
        getUserErrors
    };

}