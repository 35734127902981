import {File, FileStore} from '@webaker/package-file';
import {PropertiesHyphen} from 'csstype';
import {compile as stylisCompile, Element as StylisElement, serialize as stylisSerialize, stringify as stylisStringify} from 'stylis';
import {Theme} from './theme';

export interface ThemeRenderer {
    registerRenderer: (variant: string, options: ThemeCustomRenderer) => void;
    renderTheme: (theme: Theme) => string;
    renderThemes: (themes: Theme[]) => string;
}

export type ThemeCustomRenderer = (selector: string, properties: string) => string;

export interface ThemeRendererDeps {
    fileStore: FileStore;
}

export interface ThemeRendererConfig {
    minify?: boolean;
}

const MEDIA_TYPE = '@media';
const CONTAINER_TYPE = '@container';

export function createThemeRenderer({fileStore}: ThemeRendererDeps, {minify}: ThemeRendererConfig = {}): ThemeRenderer {

    const customRenderers: Map<string, ThemeCustomRenderer> = new Map();

    const registerRenderer = (variant: string, options: ThemeCustomRenderer): void => {
        customRenderers.set(variant, options);
    };

    const renderTheme = (theme: Theme): string => {
        const selector = `.${theme.id}`;
        const files = fileStore.getFilesByIds(theme.filesIds);
        const defaultProperties = renderProperties(theme.properties, files);
        const defaultRule = defaultProperties ? `${selector}{${defaultProperties}}` : null;
        const customRules = Object.entries(theme.customProperties ?? {}).map(([variant, properties]) => {
            const customProperties = renderProperties(properties, files);
            return customProperties ? customRenderers.get(variant)?.(selector, customProperties) : null;
        }).filter(Boolean);
        return [defaultRule, ...customRules].join('');
    };

    const renderThemes = (themes: Theme[]): string => {
        const sharedCSS = themes.filter((theme: Theme): boolean => {
            return !!theme.shared;
        }).map(renderTheme).join('');
        const personalCSS = themes.filter((theme: Theme): boolean => {
            return !theme.shared;
        }).map(renderTheme).join('');
        const cssText = [sharedCSS, personalCSS].join('');
        return minify ? minifyCSS(cssText) : cssText;
    };

    const renderProperties = (properties: PropertiesHyphen, files: File[] = []): string => {
        return Object.entries(properties ?? {}).map(([name, value]) => {
            const processedValue = processValue(value, files);
            return `${name}:${processedValue};`;
        }).join('');
    };

    const processValue = (value: string, files: File[]): string => {
        files.forEach((file: File): void => {
            value = value.replace(file.id, file.url ?? '');
        });
        return value;
    };

    const minifyCSS = (cssText: string): string => {
        const cssElements = stylisCompile(cssText);
        const newCSSElements: StylisElement[] = [];
        cssElements.forEach((element: StylisElement): void => {
            if (element.type == MEDIA_TYPE || element.type === CONTAINER_TYPE) {
                const sameElement = newCSSElements.find((currentElement: StylisElement): boolean => {
                    return currentElement.value === element.value;
                });
                if (
                    sameElement &&
                    sameElement !== element &&
                    Array.isArray(sameElement.children) &&
                    Array.isArray(element.children)
                ) {
                    sameElement.children.push(...element.children);
                    return;
                }
            }
            newCSSElements.push(element);
        });
        const newCSSText = stylisSerialize(newCSSElements, stylisStringify);
        return newCSSText.length < cssText.length ? newCSSText : cssText;
    };

    return {
        registerRenderer,
        renderTheme,
        renderThemes
    };

}