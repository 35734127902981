import {Api} from '@webaker/package-api';
import {User, UserRole} from '@webaker/package-user';

export const USER_MANAGER_PAGE_API_NAME = 'user-manager-page';

export interface UserManagerPageApi extends Api {
    name: typeof USER_MANAGER_PAGE_API_NAME;
    getUsers: () => Promise<User[]>;
    saveUser: (user: User) => Promise<User>;
    changeUserPassword: (userId: User['id'], password: NonNullable<User['password']>) => Promise<void>;
    getAllUserRoles: () => Promise<UserRole[]>;
    syncUserRoles: (userRoles: UserRole[]) => Promise<void>;
}