import {ComponentFactory, ComponentRegistry, ComponentValidator} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createHiddenComponentFactoryFunction} from '../hidden-component-factory-function';
import {createHiddenComponentOptions} from '../hidden-component-options';
import {createHiddenComponentValidationRules} from '../hidden-component-validation-rule';

export interface ProvidedHiddenComponentClientDeps {

}

export interface RequiredHiddenComponentClientDeps {
    componentFactory: ComponentFactory;
    componentRegistry: ComponentRegistry;
    componentValidator: ComponentValidator;
}

export interface HiddenComponentClientDeps extends ProvidedHiddenComponentClientDeps, RequiredHiddenComponentClientDeps {

}

export interface HiddenComponentClientConfig {

}

export type HiddenComponentClientProvider = Provider<HiddenComponentClientDeps, HiddenComponentClientConfig>;

export function createHiddenComponentClientProvider(): HiddenComponentClientProvider {
    return {

        registerServices: async ({resolve}) => {
            const componentRegistry = resolve('componentRegistry');
            const hiddenComponentOptions = createHiddenComponentOptions();
            componentRegistry.registerComponentExtension(hiddenComponentOptions);

            const componentFactory = resolve('componentFactory');
            const hiddenComponentFactoryFunction = createHiddenComponentFactoryFunction();
            componentFactory.registerFactory(hiddenComponentFactoryFunction);

            const componentValidator = resolve('componentValidator');
            const hiddenComponentValidationRules = createHiddenComponentValidationRules();
            componentValidator.registerRules(hiddenComponentValidationRules);
        }

    };
}