export interface UserNotFoundErrorDetails {
    id: string;
}

export class UserNotFoundError extends Error {

    name = 'USER_NOT_FOUND';

    details: UserNotFoundErrorDetails;

    constructor(message: string, details: UserNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}