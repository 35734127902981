import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {LoadingIndicatorCSS} from './loading-indicator-css';
import {MdIcon} from './md-icon';

export interface LoadingIndicatorProps {
    className?: string;
}

export function LoadingIndicator({className}: LoadingIndicatorProps) {

    const css = useThemedCSS(LoadingIndicatorCSS);

    return (
        <MdIcon name="go_to_line"
                weight="bold"
                className={mergeClassNames(css['loadingIndicator'], className)}/>
    );

}