import {useThemedCSS} from '@webaker/package-css-theme';
import {File} from '@webaker/package-file';
import {FileModal, FileModalChangeEvent} from '@webaker/package-file-manager';
import {mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {MutableRefObject, useCallback} from 'react';
import {FilePickerCSS} from './file-picker-css';
import {Input, InputChangeEvent} from './input';
import {MdIcon} from './md-icon';

export interface FilePickerProps {
    file: File | null;
    publish?: boolean;
    types?: string[];
    onChange?: (event: FilePickerChangeEvent) => void;
    placeholder?: string | null;
    clearable?: boolean;
    disabled?: boolean;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface FilePickerChangeEvent {
    file: File | null;
}

export function FilePicker({
    file,
    publish,
    types,
    onChange,
    placeholder,
    clearable,
    disabled,
    className,
    inputRef
}: FilePickerProps) {

    const css = useThemedCSS(FilePickerCSS, {});
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const handleChange = useCallback((event: FileModalChangeEvent) => {
        onChange?.(event);
        closeModal();
    }, [onChange]);

    const handleInputChange = useCallback((event: InputChangeEvent) => {
        if (!event.value) {
            onChange?.({file: null});
        }
    }, [onChange]);

    const fileModal = isModalOpen ? (
        <FileModal file={file}
                   publish={publish}
                   types={types}
                   onChange={handleChange}
                   onClose={closeModal}/>
    ) : null;

    return (
        <Input value={file?.name ?? null}
               onChange={handleInputChange}
               placeholder={placeholder}
               readonly={true}
               clearable={clearable}
               disabled={disabled}
               outside={fileModal}
               className={mergeClassNames(css['filePicker'], className)}
               inputRef={inputRef}>
            <button className={css['uploadButton']}
                    onClick={openModal}
                    disabled={disabled}>
                <MdIcon name="folder"/>
            </button>
        </Input>
    );

}