import {CSSThemeStore} from '@webaker/package-css-theme';
import {ThemeCustomRenderer} from '@webaker/package-theme';

export interface MobileThemeRenderer extends ThemeCustomRenderer {

}

export interface MobileThemeRendererDeps {
    cssThemeStore: CSSThemeStore;
}

export interface MobileThemeRendererConfig {
    container?: boolean;
}

export function createMobileThemeRenderer({cssThemeStore}: MobileThemeRendererDeps, {container}: MobileThemeRendererConfig = {}): MobileThemeRenderer {
    return (selector: string, properties: string): string => {
        const theme = cssThemeStore.getCurrentCSSTheme();
        const mobileWidth = theme.deviceWidth.mobile;
        return [
            `@media(width<=${mobileWidth}){${selector}{${properties}}}`,
            container ? `@container(width<=${mobileWidth}){${selector}{${properties}}}` : ''
        ].join('');
    };
}