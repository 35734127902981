import {useThemedCSS} from '@webaker/package-css-theme';
import {Corner, mergeClassNames, useDocumentEvent, useReactiveRef} from '@webaker/package-utils';
import {ChangeEvent, CSSProperties, MouseEvent, MutableRefObject, ReactNode, RefObject, useCallback, useLayoutEffect, useRef, useState} from 'react';
import {InputCSS} from './input-css';
import {MdIcon} from './md-icon';
import {Portal} from './portal';

export interface InputProps {
    value?: string | null,
    onChange?: (event: InputChangeEvent) => void;
    onClick?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    icon?: ReactNode;
    placeholder?: string | null;
    children?: ReactNode;
    outside?: ReactNode;
    readonly?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    password?: boolean;
    className?: string;
    style?: CSSProperties;
    elementRef?: MutableRefObject<HTMLDivElement | null>;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface InputChangeEvent {
    value: string | null;
}

export function Input({
    value,
    onChange,
    onClick,
    onFocus,
    onBlur,
    icon,
    placeholder,
    children,
    outside,
    readonly,
    clearable,
    disabled,
    invalid,
    password,
    className,
    style,
    elementRef,
    inputRef
}: InputProps) {

    const css = useThemedCSS(InputCSS, {});
    const outsideRef = useReactiveRef<HTMLDivElement | null>(null);
    const internalRef = useRef<HTMLDivElement | null>(null);
    const containerRef = elementRef ?? internalRef;
    const corner = useCorner(containerRef, outsideRef);

    const handleChange = useCallback((event: ChangeEvent) => {
        const input = event.target as HTMLInputElement;
        onChange?.({value: input.value || null});
    }, [onChange]);

    const handleClear = useCallback((event: MouseEvent) => {
        onChange?.({value: null});
        event.stopPropagation();
    }, [onChange]);

    return (
        <div ref={containerRef}
             className={mergeClassNames(
                 css['input'],
                 disabled && css['is-disabled'],
                 invalid && css['is-invalid'],
                 className
             )}
             onClick={onClick}
             style={style}>
            {icon && (
                <span className={css['icon']}>
                    {icon}
                </span>
            )}
            <input ref={inputRef}
                   type={password ? 'password' : 'text'}
                   value={value ?? ''}
                   placeholder={placeholder ?? undefined}
                   onChange={handleChange}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   className={css['nativeInput']}
                   disabled={disabled}
                   spellCheck={false}
                   readOnly={readonly}/>
            {clearable && value && !disabled && (
                <button className={css['clearButton']}
                        onClick={handleClear}>
                    <MdIcon name="close"/>
                </button>
            )}
            {children}
            {outside && (
                <Portal track={true}>
                    <div ref={outsideRef}
                         className={mergeClassNames(css['outside'], css[`outside-${corner}`])}>
                        {outside}
                    </div>
                </Portal>
            )}
        </div>
    );

}

function useCorner(containerRef: RefObject<HTMLElement | null>, outsideRef: RefObject<HTMLElement | null>): Corner {
    const [corner, setCorner] = useState<Corner>('bottom-right');
    const updateCorner = () => {
        if (containerRef.current && outsideRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const outsideRect = outsideRef.current?.getBoundingClientRect();
            const bottomEdge = containerRect.bottom + outsideRect.height;
            const leftEdge = containerRect.right - outsideRect.width;
            const vertical = bottomEdge > window.innerHeight ? 'top' : 'bottom';
            const horizontal = leftEdge < 0 ? 'left' : 'right';
            setCorner(`${vertical}-${horizontal}`);
        }
    };
    if (typeof window !== 'undefined') {
        useLayoutEffect(updateCorner, [outsideRef.current]);
        useDocumentEvent('scroll', updateCorner, []);
    }
    return corner;
}