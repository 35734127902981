import {Provider} from '@webaker/package-deps';
import {IdGenerator} from '@webaker/package-utils';
import {ApiClientFactory, createApiClientFactory} from './api-client-factory';
import {ApiClientRunner, createApiClientRunner} from './api-client-runner';

export interface ProvidedApiClientDeps {
    apiClientFactory: ApiClientFactory;
    apiClientRunner: ApiClientRunner;
}

export interface RequiredApiClientDeps {
    idGenerator: IdGenerator;
}

export interface ApiClientDeps extends ProvidedApiClientDeps, RequiredApiClientDeps {

}

export interface ApiClientConfig {
    apiRoute: string;
    apiDelay: number;
}

export type ApiClientProvider = Provider<ApiClientDeps, ApiClientConfig>;

export function createApiClientProvider(): ApiClientProvider {
    return {

        registerDependencies: async ({register}, config) => {
            register('apiClientFactory', ({resolve}) => {
                return createApiClientFactory({
                    apiClientRunner: resolve('apiClientRunner')
                });
            });
            register('apiClientRunner', ({resolve}) => {
                return createApiClientRunner({
                    idGenerator: resolve('idGenerator'),
                    fetch: window.fetch
                }, {
                    apiRoute: config.apiRoute,
                    apiDelay: config.apiDelay
                });
            });
        }

    };
}