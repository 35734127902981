import {Extension} from '../extension';
import {ExtensionApi} from '../extension-api';
import {ExtensionManager} from '../extension-manager';
import {ExtensionNotFoundError} from '../extension-not-found-error';
import {ExtensionRestart} from '../extension-restart';
import {EXTENSIONS_ELEMENT_ID} from '../extensions-injector';

export interface ExtensionClientManager<E extends Extension = Extension> extends ExtensionManager<E> {
    setApi: (api: ExtensionApi) => void;
}

export function createExtensionClientManager<E extends Extension = Extension>(): ExtensionClientManager<E> {

    let extensionRestart: ExtensionRestart | null = null;
    let extensionApi: ExtensionApi | null = null;

    const extensionsMap: Map<E['name'], E> = new Map();
    const enabledExtensionsNamesSet: Set<E['name']> = new Set();

    const setExtensions = (extensions: E[]): void => {
        extensionsMap.clear();
        extensions.forEach((extension: E): void => {
            extensionsMap.set(extension.name, extension);
        });
    };

    const setRestart = (restart: ExtensionRestart): void => {
        extensionRestart = restart;
    };

    const setApi = (api: ExtensionApi): void => {
        extensionApi = api;
    };

    const getExtensionByName = (name: E['name']): E | null => {
        return extensionsMap.get(name) || null;
    };

    const getAllExtensions = (): E[] => {
        return Array.from(extensionsMap.values());
    };

    const getAllExtensionsNames = (): E['name'][] => {
        return Array.from(extensionsMap.keys());
    };

    const getEnabledExtensions = (): E[] => {
        return getAllExtensions().filter((extension: E): boolean => {
            return enabledExtensionsNamesSet.has(extension.name);
        });
    };

    const getDisabledExtensions = (): E[] => {
        return getAllExtensions().filter((extension: E): boolean => {
            return !enabledExtensionsNamesSet.has(extension.name);
        });
    };

    const getEnabledExtensionsNames = (): Extension['name'][] => {
        return getAllExtensionsNames().filter((name: Extension['name']): boolean => {
            return enabledExtensionsNamesSet.has(name);
        });
    };

    const enableExtension = async (name: E['name']): Promise<void> => {
        if (!extensionsMap.has(name)) {
            throw new ExtensionNotFoundError(`Extension [${name}] not found`, {name});
        }
        if (enabledExtensionsNamesSet.has(name)) {
            return;
        }
        await extensionApi?.enableExtension(name);
        await extensionRestart?.();
    };

    const disableExtension = async (name: E['name']): Promise<void> => {
        if (!extensionsMap.has(name)) {
            throw new ExtensionNotFoundError(`Extension [${name}] not found`, {name});
        }
        if (!enabledExtensionsNamesSet.has(name)) {
            return;
        }
        await extensionApi?.disableExtension(name);
        await extensionRestart?.();
    };

    const initEnabledExtensions = (): void => {
        const extensionsScript = document.getElementById(EXTENSIONS_ELEMENT_ID);
        if (extensionsScript) {
            const enabledExtensionsNames = JSON.parse(extensionsScript.innerText.trim());
            if (Array.isArray(enabledExtensionsNames)) {
                enabledExtensionsNames.forEach((name: E['name']): void => {
                    enabledExtensionsNamesSet.add(name);
                });
            }
        }
    };

    initEnabledExtensions();

    return {
        setExtensions,
        setRestart,
        setApi,
        getExtensionByName,
        getAllExtensions,
        getAllExtensionsNames,
        getEnabledExtensions,
        getEnabledExtensionsNames,
        getDisabledExtensions,
        enableExtension,
        disableExtension
    };

}