import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FilePickerClassName = 'filePicker' | 'uploadButton';

export interface FilePickerCSSProps extends CSSThemeProps {

}

export function FilePickerCSS({}: FilePickerCSSProps) {
    return css<FilePickerClassName>`

        .filePicker {

        }

        .uploadButton {

        }

    `;
}