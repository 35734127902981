import {PageMetadataService, TITLE_METADATA_KEY} from '@webaker/app';
import {EventActions, EventBus, EventSubscriber} from '@webaker/package-event-bus';
import {LOGIN_USER_EVENT, LoginUserEvent, LOGOUT_USER_EVENT, LogoutUserEvent} from '@webaker/package-user';
import {AppRouter, OPEN_PAGE_EVENT, OpenPageEvent} from '../app-router';
import {Page} from '../page/page';

export interface AppClientEventSubscriber extends EventSubscriber {

}

export interface AppClientEventSubscriberDeps {
    appRouter: AppRouter;
    eventBus: EventBus;
    pageMetadataService: PageMetadataService;
}

const INTERNAL_NAVIGATION_INFO = 'internal';
const WINDOW_NAVIGATE_EVENT = 'navigate';
const RELOAD_NAVIGATION_TYPE = 'reload';

export function createAppClientEventSubscriber({
    appRouter,
    eventBus,
    pageMetadataService
}: AppClientEventSubscriberDeps): AppClientEventSubscriber {

    const subscribeEvents = (): void => {
        window.navigation.addEventListener(WINDOW_NAVIGATE_EVENT, onNavigate);
        eventBus.addEventListener(OPEN_PAGE_EVENT, onOpenPage);
        eventBus.addEventListener(LOGIN_USER_EVENT, onLoginUser);
        eventBus.addEventListener(LOGOUT_USER_EVENT, onLogoutUser);
    };

    const unsubscribeEvents = (): void => {
        window.navigation.removeEventListener(WINDOW_NAVIGATE_EVENT, onNavigate);
        eventBus.removeEventListener(OPEN_PAGE_EVENT, onOpenPage);
        eventBus.removeEventListener(LOGIN_USER_EVENT, onLoginUser);
        eventBus.removeEventListener(LOGOUT_USER_EVENT, onLogoutUser);
    };

    const onNavigate = async (event: NavigateEvent) => {
        if (!canIntercept(event)) {
            return;
        }
        if (event.info === INTERNAL_NAVIGATION_INFO) {
            return event.intercept();
        }
        event.intercept({
            handler: async () => {
                const url = new URL(event.destination.url);
                await appRouter.openPageByPath(url.pathname);
            }
        });
    };

    const onLoginUser = async ({}: LoginUserEvent, {onSuccess}: EventActions): Promise<void> => {
        onSuccess(() => {
            window.location.reload();
        });
    };

    const onLogoutUser = async ({}: LogoutUserEvent, {onSuccess}: EventActions): Promise<void> => {
        onSuccess(() => {
            window.location.reload();
        });
    };

    const canIntercept = (event: NavigateEvent): boolean => {
        return (
            event.canIntercept &&
            !event.hashChange &&
            event.downloadRequest === null &&
            event.navigationType !== RELOAD_NAVIGATION_TYPE
        );
    };

    const onOpenPage = async ({pageContent}: OpenPageEvent, {onSuccess}: EventActions): Promise<void> => {
        onSuccess(() => {
            updateTitle(pageContent.page);
            updatePath(pageContent.page);
        });
    };

    const updateTitle = (page: Page): void => {
        document.title = pageMetadataService.getMetadata(TITLE_METADATA_KEY, page) ?? '';
    };

    const updatePath = (page: Page): void => {
        const currentNavigationPath = getCurrentNavigationPath();
        if (page.path !== currentNavigationPath) {
            window.navigation.navigate(page.path, {
                info: INTERNAL_NAVIGATION_INFO
            });
        }
    };

    const getCurrentNavigationPath = (): Page['path'] => {
        const url = window.navigation.currentEntry?.url ? new URL(window.navigation.currentEntry.url) : null;
        return url?.pathname ?? '/';
    };

    return {
        subscribeEvents,
        unsubscribeEvents
    };

}