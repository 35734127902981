import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {PartialPermission, Permission} from './permission';

export interface PermissionFactory {
    createPermission: (data: PartialPermission) => Permission;
}

export interface PermissionFactoryDeps {
    idGenerator: IdGenerator;
}

export function createPermissionFactory({idGenerator}: PermissionFactoryDeps): PermissionFactory {

    const createPermission = (data: PartialPermission): Permission => {
        return omitUndefined({
            id: data.id ?? idGenerator.generateId(),
            type: data.type,
            entity: data.entity ?? undefined,
            grant: data.grant ?? false,
            except: data.grantExcept ?? []
        });
    };

    return {
        createPermission
    };

}