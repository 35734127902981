import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {mergeClassNames, useRerender} from '@webaker/package-utils';
import {useEffect} from 'react';
import {Button} from './button';
import {MdIcon} from './md-icon';
import {Toast, ToastService} from './services/toast-service';
import {ToastCSS} from './toast-css';

export interface ToastsProps {
    className?: string;
}

export interface ToastsDeps {
    toastService: ToastService;
}

export function Toasts({className}: ToastsProps) {

    const css = useThemedCSS(ToastCSS);
    const toastService = useDependency<ToastsDeps>()('toastService');
    const rerender = useRerender();

    useEffect(() => {
        toastService.watch(rerender);
        return () => {
            toastService.unwatch(rerender);
        };
    }, []);

    return (
        <div className={css['wrapper']}>
            {toastService.getToasts().map((toast: Toast) => (
                <div key={toast.id}
                     className={mergeClassNames(css['toast'], className)}>
                    <div className={css['message']}>
                        {toast.message}
                    </div>
                    <Button color="white"
                            icon={<MdIcon name="close"/>}
                            onClick={() => toastService.removeToast(toast.id)}
                            className={css['button']}/>
                </div>
            ))}
        </div>
    );

}

export interface UseToastDeps {
    toastService: ToastService;
}

export function useToast(): ToastService['addToast'] {
    return useDependency<UseToastDeps>()('toastService').addToast;
}