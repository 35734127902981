import {ApiClientFactory} from '@webaker/package-api/client';
import {PERMISSION_API_NAME, PermissionApi} from '../permission-api';

export interface PermissionClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createPermissionClientApi({apiClientFactory}: PermissionClientApiDeps): PermissionApi {

    return apiClientFactory.createApi<PermissionApi>(PERMISSION_API_NAME, {
        getPermission: ['type', 'entity'],
        savePermission: ['permission'],
        deletePermission: ['id']
    });

}