import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {PortalCSS} from './portal-css';

export interface PortalProps {
    children: ReactNode;
    fixed?: boolean;
    track?: boolean;
}

export function Portal({children, fixed, track}: PortalProps) {

    const portalRef = useRef<HTMLDivElement | null>(null);
    const targetRef = useRef<HTMLDivElement | null>(null);
    const css = useThemedCSS(PortalCSS, {});
    const [isPortalVisible, setIsPortalVisible] = useState(false);

    if (typeof window !== 'undefined') {
        useLayoutEffect(() => {
            let frameId: number | null = null;
            const updateStyles = () => {
                const portalElement = portalRef.current;
                const targetElement = targetRef.current;
                if (portalElement && targetElement) {
                    const parentElement = portalElement.parentNode as HTMLElement;
                    const portalRect = portalElement.getBoundingClientRect();
                    const parentRect = parentElement.getBoundingClientRect();
                    const portalStyles = window.getComputedStyle(portalElement);
                    targetElement.style.left = `${parentRect.left}px`;
                    targetElement.style.top = `${parentRect.top}px`;
                    targetElement.style.width = `${parentRect.width}px`;
                    targetElement.style.height = `${parentRect.height}px`;
                    targetElement.style.paddingLeft = `${portalRect.left - parentRect.left}px`;
                    targetElement.style.paddingTop = `${portalRect.top - parentRect.top}px`;
                    targetElement.style.fontSize = portalStyles.fontSize;
                    targetElement.style.fontFamily = portalStyles.fontFamily;
                }
                if (track) {
                    frameId = window.requestAnimationFrame(updateStyles);
                }
            };
            updateStyles();
            return () => {
                if (frameId) {
                    window.cancelAnimationFrame(frameId);
                }
            };
        }, [targetRef.current, portalRef.current]);
    }

    useEffect(() => {
        setIsPortalVisible(true);
    }, []);

    return (
        <div ref={portalRef}
             className={mergeClassNames(
                 css['portal'],
                 fixed && css['is-fixed']
             )}>
            {isPortalVisible && createPortal((
                <div ref={targetRef} className={css['target']}>
                    {children}
                </div>
            ), document.body)}
        </div>
    );

}