import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';

export type SpiderImageViewClassName = 'spiderImage';

export function SpiderImageCSS({}: CSSThemeProps) {
    return css<SpiderImageViewClassName>`

        .spiderImage {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            aspect-ratio: 1;
        }

    `;
}

export interface SpiderImagePrivateCSSProps extends CSSThemeProps {
    size: CSSThemeSize;
}

export function SpiderImagePrivateCSS({size}: SpiderImagePrivateCSSProps) {
    return css<SpiderImageViewClassName>`

        .spiderImage {
            max-width: ${getSizeMap(size)};
        }

    `;
}

function getSizeMap(size: CSSThemeSize): string {
    return {
        none: '0px',
        verySmall: '50px',
        small: '100px',
        medium: '150px',
        large: '200px',
        veryLarge: '250px'
    }[size];
}