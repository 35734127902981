import {Store, StoreFactory} from '@webaker/package-store';
import {User} from './user';

export const USER_STORE_NAME = 'user';

export interface UserStoreState {
    users: User[];
    loggedUserId: User['id'] | null;
}

export interface UserStore extends Store<UserStoreState> {

    name: typeof USER_STORE_NAME;

    getUsers: () => User[];
    setUsers: (users: User[]) => void;
    addUser: (user: User) => void;
    updateUser: (user: User) => void;

    getLoggedUser: () => User | null;
    setLoggedUser: (user: User | null) => void;

}

export interface UserStoreDeps {
    storeFactory: StoreFactory;
}

export function createUserStore({storeFactory}: UserStoreDeps): UserStore {

    return storeFactory.createStore<UserStore>({

        name: USER_STORE_NAME,

        state: {
            users: [],
            loggedUserId: null
        },

        getters: {

            getUsers: ({state}): User[] => {
                return state.users;
            },

            getLoggedUser: ({state}): User | null => {
                return state.users.find((user: User) => {
                    return user.id === state.loggedUserId;
                }) ?? null;
            }

        },

        setters: {

            setUsers: ({state}, users: User[]): UserStoreState => {
                return {
                    ...state,
                    users
                };
            },

            addUser: ({state}, user: User): UserStoreState => {
                return {
                    ...state,
                    users: [...state.users, user]
                };
            },

            setLoggedUser: ({state}, user: User | null): UserStoreState => {
                return {
                    ...state,
                    users: user ? [
                        ...state.users,
                        user
                    ] : state.users,
                    loggedUserId: user?.id ?? null
                };
            },

            updateUser: ({state}, updatedUser: User): UserStoreState => {
                return {
                    ...state,
                    users: state.users.map((user: User) => {
                        if (user.id === updatedUser.id) {
                            return updatedUser;
                        }
                        return user;
                    })
                };
            }

        }

    });

}