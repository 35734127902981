import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useClickOutside, useHotkey} from '@webaker/package-utils';
import {MutableRefObject, useCallback, useRef, useState} from 'react';
import {ASTERISK} from './asterisk';
import {Autocomplete, AutocompleteOption} from './autocomplete';
import {Input, InputChangeEvent} from './input';
import {MdIcon} from './md-icon';
import {SelectBoxCSS} from './select-box-css';

export interface SelectBoxProps<T = any> {
    value: T | null;
    options: SelectBoxOption<T>[];
    onChange: (event: SelectBoxChangeEvent<T>) => void;
    search?: boolean;
    placeholder?: string | null;
    clearable?: boolean;
    disabled?: boolean;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface SelectBoxOption<T = any> extends AutocompleteOption<T> {

}

export interface SelectBoxChangeEvent<T = any> {
    value: T | null;
}

export function SelectBox<T = any>({
    value,
    options,
    search = true,
    placeholder,
    onChange,
    clearable,
    disabled,
    className,
    inputRef
}: SelectBoxProps<T>) {

    const [isOpen, setIsOpen] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const autocompleteRef = useRef<HTMLElement | null>(null);
    const css = useThemedCSS(SelectBoxCSS, {});

    const handleSelect = useCallback((event: SelectBoxChangeEvent) => {
        if (!disabled) {
            onChange?.(event);
            setIsOpen(false);
        }
    }, [onChange, disabled]);

    const handleInputChange = useCallback((event: InputChangeEvent) => {
        if (!event.value) {
            onChange?.({value: null});
            setIsOpen(false);
        }
    }, [onChange, disabled]);

    const handleToggle = useCallback(() => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    }, [isOpen, disabled]);

    useClickOutside([elementRef, autocompleteRef], () => {
        setIsOpen(false);
    }, []);

    useHotkey('Escape', () => {
        setIsOpen(false);
    }, []);

    useHotkey('Space', () => {
        if (document.activeElement?.parentNode === elementRef.current) {
            setIsOpen(true);
        }
    }, []);

    const currentOption = options.find((option: SelectBoxOption) => {
        return option.value === value;
    }) ?? null;

    const autocomplete = isOpen ? (
        <Autocomplete options={options}
                      value={value}
                      onSelect={handleSelect}
                      search={search}
                      elementRef={autocompleteRef}/>
    ) : null;

    return (
        <Input elementRef={elementRef}
               inputRef={inputRef}
               onChange={handleInputChange}
               onClick={handleToggle}
               icon={currentOption?.icon}
               value={currentOption ? currentOption.label + (currentOption.asterisk ? ASTERISK : '') : null}
               readonly={true}
               outside={autocomplete}
               placeholder={placeholder}
               clearable={clearable}
               disabled={disabled}
               className={mergeClassNames(
                   css['selectBox'],
                   isOpen && css['is-open'],
                   className
               )}>
            <button className={css['arrowButton']}
                    onClick={handleToggle}
                    disabled={disabled}>
                <MdIcon name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}/>
            </button>
        </Input>
    );

}

export function getOptionLabel(options: SelectBoxOption[], value: string | null | undefined): string | null {
    return options.find((option: SelectBoxOption) => {
        return option.value === value;
    })?.label ?? null;
}