import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {spiderNetPng} from '../assets/spider-net';

export type SpiderNetBackgroundViewClassName = 'spiderNetBackground' | 'pattern';

export interface SpiderNetBackgroundCSSProps extends CSSThemeProps {
    deltaX: number;
    deltaY: number;
}

export function SpiderNetBackgroundCSS({theme, deltaX, deltaY}: SpiderNetBackgroundCSSProps) {
    return css<SpiderNetBackgroundViewClassName>`

        .spiderNetBackground {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            perspective: 1000px;
            overflow: hidden;
            pointer-events: none;
            z-index: -1;
        }

        .pattern {
            position: absolute;
            width: 200%;
            height: 200%;
            left: -50%;
            top: -50%;
            background-color: transparent;
            background-position: center;
            background-repeat: repeat;
            background-image: url(${spiderNetPng}), radial-gradient(${theme.color.white} 0%, ${theme.color.veryLight} 50%);
            filter: blur(2px);
            transform: translateX(calc(1px * ${deltaX} / 50)) translateY(calc(1px * ${deltaY} / 50)) rotateX(calc(1deg * ${deltaY} / 1000)) rotateY(calc(1deg * ${deltaX} / 1000));
            transition: transform ${theme.transition.short};
            will-change: transform;

            @media (max-width: ${theme.deviceWidth.mobile}) {
                transition: none;
            }
        }

    `;
}