import {Provider} from '@webaker/package-deps';
import {ThemeRenderer} from '@webaker/package-theme';
import {createHoverThemeRenderer, HoverThemeRenderer} from '../hover-theme-renderer';
import {HOVER_THEME_VARIANT} from '../hover-theme-variant';

export interface ProvidedHoverThemeClientDeps {
    hoverThemeRenderer: HoverThemeRenderer;
}

export interface RequiredHoverThemeClientDeps {
    themeRenderer: ThemeRenderer;
}

export interface HoverThemeClientDeps extends ProvidedHoverThemeClientDeps, RequiredHoverThemeClientDeps {

}

export interface HoverThemeClientConfig {

}

export type HoverThemeClientProvider = Provider<HoverThemeClientDeps, HoverThemeClientConfig>;

export function createHoverThemeClientProvider(): HoverThemeClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('hoverThemeRenderer', () => {
                return createHoverThemeRenderer();
            });
        },

        registerServices: async ({resolve}) => {
            const themeRenderer = resolve('themeRenderer');
            const hoverThemeRenderer = resolve('hoverThemeRenderer');
            themeRenderer.registerRenderer(HOVER_THEME_VARIANT, hoverThemeRenderer);
        }

    };
}