import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {HiddenComponentMode} from './hidden-component';

export type HiddenComponentViewWrapperClassName = 'hiddenComponent' | `hidden-${HiddenComponentMode}`;

export interface HiddenComponentViewWrapperCSSProps extends CSSThemeProps {

}

export function HiddenComponentViewWrapperCSS({theme}: HiddenComponentViewWrapperCSSProps) {
    return css<HiddenComponentViewWrapperClassName>`

        .hiddenComponent {

        }

        .hidden-everywhere {
            display: none !important;
        }


        .hidden-onMobile {
            @media (width <= ${theme.deviceWidth.mobile}) {
                display: none !important;
            }
            @container (width <= ${theme.deviceWidth.mobile}) {
                display: none !important;
            }
        }

        .hidden-onDesktop {
            @media (width >= ${theme.deviceWidth.desktop}) {
                display: none !important;
            }
            @container (width >= ${theme.deviceWidth.desktop}) {
                display: none !important;
            }
        }

    `;
}