import {Api} from '@webaker/package-api';
import {Permission} from './permission';

export const PERMISSION_API_NAME = 'permission';

export interface PermissionApi extends Api {
    name: typeof PERMISSION_API_NAME;
    getPermission: (type: Permission['type'], entity?: NonNullable<Permission['entity']>) => Promise<Permission | null>;
    savePermission: (permission: Permission) => Promise<void>;
    deletePermission: (id: Permission['id']) => Promise<void>;
}