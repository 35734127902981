import {File} from '@webaker/package-file';
import {PropertiesHyphen} from 'csstype';

export interface Theme {
    id: string;
    filesIds: File['id'][];
    name: string;
    properties: PropertiesHyphen;
    customProperties: Record<string, PropertiesHyphen>;
    shared?: boolean;
}