import {ApiClientFactory} from '@webaker/package-api/client';
import {ClassNameRegistry} from '@webaker/package-css';
import {CSSThemeStore} from '@webaker/package-css-theme';
import {DependencyContainer, Provider} from '@webaker/package-deps';
import {EventBus} from '@webaker/package-event-bus';
import {FileApi, FileStore} from '@webaker/package-file';
import {FlexBoxFactory, FlexBoxValidationRule} from '@webaker/package-flex-box';
import {SettingsStore} from '@webaker/package-settings';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {UserStore} from '@webaker/package-user';
import {AppMode, IdGenerator, ReactWrapper} from '@webaker/package-utils';
import {Validator} from '@webaker/package-validation';
import {AppApi} from '../app-api';
import {AppManager} from '../app-manager';
import {AppRenderer, createAppRenderer} from '../app-renderer';
import {AppRouter, createAppRouter} from '../app-router';
import {AppStore, createAppStore} from '../app-store';
import {ComponentFactory, createComponentFactory} from '../component/component-factory';
import {ComponentRegistry, createComponentRegistry} from '../component/component-registry';
import {ComponentRenderer, createComponentRenderer} from '../component/component-renderer';
import {ComponentValidator, createComponentValidator} from '../component/component-validator';
import {ComponentsTreeMutator, createComponentsTreeMutator} from '../content/components-tree-mutator';
import {ComponentsTreeSelector, createComponentsTreeSelector} from '../content/components-tree-selector';
import {createHooksTreeMutator, HooksTreeMutator} from '../content/hooks-tree-mutator';
import {createHooksTreeSelector, HooksTreeSelector} from '../content/hooks-tree-selector';
import {createPageContentMutator, PageContentMutator} from '../content/page-content-mutator';
import {createPageContentSelector, PageContentSelector} from '../content/page-content-selector';
import {createPagesTreeMutator, PagesTreeMutator} from '../content/pages-tree-mutator';
import {createPagesTreeSelector, PagesTreeSelector} from '../content/pages-tree-selector';
import {createHookFactory, HookFactory} from '../hook/hook-factory';
import {createHookValidator, HookValidator} from '../hook/hook-validator';
import {createPageFactory, PageFactory} from '../page/page-factory';
import {createPageMetadataService, PageMetadataService} from '../page/page-metadata-service';
import {createPageRegistry, PageRegistry} from '../page/page-registry';
import {createPageRenderer, PageRenderer} from '../page/page-renderer';
import {createPageStore, PageStore} from '../page/page-store';
import {createPageValidator, PageValidator} from '../page/page-validator';
import {createAppClientApi} from './app-client-api';
import {AppClientEventSubscriber, createAppClientEventSubscriber} from './app-client-event-subscriber';
import {createAppClientManager} from './app-client-manager';

export interface ProvidedAppClientDeps {
    appApi: AppApi;
    appClientEventSubscriber: AppClientEventSubscriber;
    appManager: AppManager;
    appRenderer: AppRenderer;
    appRouter: AppRouter;
    appStore: AppStore;
    clientAppWorker: AppClientEventSubscriber;
    componentFactory: ComponentFactory;
    componentRegistry: ComponentRegistry;
    componentRenderer: ComponentRenderer;
    componentValidator: ComponentValidator;
    componentsTreeMutator: ComponentsTreeMutator;
    componentsTreeSelector: ComponentsTreeSelector;
    hookFactory: HookFactory;
    hookValidator: HookValidator;
    hooksTreeMutator: HooksTreeMutator;
    hooksTreeSelector: HooksTreeSelector;
    pageContentMutator: PageContentMutator;
    pageContentSelector: PageContentSelector;
    pageFactory: PageFactory;
    pageMetadataService: PageMetadataService;
    pageRegistry: PageRegistry;
    pageRenderer: PageRenderer;
    pageStore: PageStore;
    pageValidator: PageValidator;
    pagesTreeMutator: PagesTreeMutator;
    pagesTreeSelector: PagesTreeSelector;
}

export interface RequiredAppClientDeps {
    apiClientFactory: ApiClientFactory;
    classNameRegistry: ClassNameRegistry;
    container: DependencyContainer<any>;
    cssThemeStore: CSSThemeStore;
    eventBus: EventBus;
    fileApi: FileApi;
    fileStore: FileStore;
    flexBoxFactory: FlexBoxFactory;
    flexBoxValidationRule: FlexBoxValidationRule;
    idGenerator: IdGenerator;
    reactWrapper: ReactWrapper;
    settingsStore: SettingsStore;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
    userStore: UserStore;
    validator: Validator;
}

export interface AppClientDeps extends ProvidedAppClientDeps, RequiredAppClientDeps {

}

export interface AppClientConfig {
    appMode: AppMode;
    appName: string;
}

export type AppClientProvider = Provider<AppClientDeps, AppClientConfig>;

export function createAppClientProvider(): AppClientProvider {
    return {

        registerDependencies: async ({register}, config) => {
            register('appApi', ({resolve}) => {
                return createAppClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('appManager', ({resolve}) => {
                return createAppClientManager({
                    appRenderer: resolve('appRenderer'),
                    appRouter: resolve('appRouter'),
                    appStore: resolve('appStore'),
                    container: resolve('container'),
                    eventBus: resolve('eventBus'),
                    pageRenderer: resolve('pageRenderer')
                }, config);
            });
            register('appRenderer', ({resolve}) => {
                return createAppRenderer({
                    appStore: resolve('appStore'),
                    pageRenderer: resolve('pageRenderer')
                });
            });
            register('appRouter', ({resolve}) => {
                return createAppRouter({
                    appApi: resolve('appApi'),
                    appStore: resolve('appStore'),
                    eventBus: resolve('eventBus'),
                    pageRenderer: resolve('pageRenderer')
                });
            });
            register('appClientEventSubscriber', ({resolve}) => {
                return createAppClientEventSubscriber({
                    appRouter: resolve('appRouter'),
                    eventBus: resolve('eventBus'),
                    pageMetadataService: resolve('pageMetadataService'),
                });
            });
            register('appStore', ({resolve}) => {
                return createAppStore({
                    pageContentMutator: resolve('pageContentMutator'),
                    pageContentSelector: resolve('pageContentSelector'),
                    storeFactory: resolve('storeFactory')
                });
            });
            register('componentFactory', ({resolve}) => {
                return createComponentFactory({
                    componentRegistry: resolve('componentRegistry'),
                    flexBoxFactory: resolve('flexBoxFactory'),
                    idGenerator: resolve('idGenerator')
                });
            });
            register('componentRegistry', () => {
                return createComponentRegistry();
            });
            register('componentRenderer', ({resolve}) => {
                return createComponentRenderer({
                    appStore: resolve('appStore'),
                    componentRegistry: resolve('componentRegistry'),
                    reactWrapper: resolve('reactWrapper')
                });
            });
            register('componentValidator', ({resolve}) => {
                return createComponentValidator({
                    componentRegistry: resolve('componentRegistry'),
                    flexBoxValidationRule: resolve('flexBoxValidationRule'),
                    validator: resolve('validator')
                });
            });
            register('componentsTreeMutator', ({resolve}) => {
                return createComponentsTreeMutator({
                    componentsTreeSelector: resolve('componentsTreeSelector'),
                    hookFactory: resolve('hookFactory'),
                    hooksTreeMutator: resolve('hooksTreeMutator'),
                    hooksTreeSelector: resolve('hooksTreeSelector')
                });
            });
            register('componentsTreeSelector', ({resolve}) => {
                return createComponentsTreeSelector({
                    hooksTreeSelector: resolve('hooksTreeSelector')
                });
            });
            register('hookFactory', ({resolve}) => {
                return createHookFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('hookValidator', ({resolve}) => {
                return createHookValidator({
                    validator: resolve('validator')
                });
            });
            register('hooksTreeMutator', () => {
                return createHooksTreeMutator();
            });
            register('hooksTreeSelector', () => {
                return createHooksTreeSelector();
            });
            register('pageContentMutator', ({resolve}) => {
                return createPageContentMutator({
                    componentsTreeMutator: resolve('componentsTreeMutator')
                });
            });
            register('pageContentSelector', ({resolve}) => {
                return createPageContentSelector({
                    componentsTreeSelector: resolve('componentsTreeSelector')
                });
            });
            register('pageFactory', ({resolve}) => {
                return createPageFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('pageMetadataService', ({resolve}) => {
                return createPageMetadataService({
                    fileStore: resolve('fileStore'),
                    settingsStore: resolve('settingsStore')
                }, {
                    appName: config.appName
                });
            });
            register('pageRegistry', ({}) => {
                return createPageRegistry();
            });
            register('pageRenderer', ({resolve}) => {
                return createPageRenderer({
                    appStore: resolve('appStore'),
                    componentRenderer: resolve('componentRenderer'),
                    pageRegistry: resolve('pageRegistry'),
                    reactWrapper: resolve('reactWrapper')
                });
            });
            register('pageStore', ({resolve}) => {
                return createPageStore({
                    storeFactory: resolve('storeFactory'),
                    pagesTreeMutator: resolve('pagesTreeMutator'),
                    pagesTreeSelector: resolve('pagesTreeSelector')
                });
            });
            register('pageValidator', ({resolve}) => {
                return createPageValidator({
                    validator: resolve('validator'),
                    pageRegistry: resolve('pageRegistry')
                });
            });
            register('pagesTreeMutator', ({resolve}) => {
                return createPagesTreeMutator({
                    pagesTreeSelector: resolve('pagesTreeSelector')
                });
            });
            register('pagesTreeSelector', () => {
                return createPagesTreeSelector();
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const appStore = resolve('appStore');
            const pageStore = resolve('pageStore');
            storeRegistry.registerStore(appStore);
            storeRegistry.registerStore(pageStore);
        },

        runServices: async ({resolve}) => {
            const appClientEventSubscriber = resolve('appClientEventSubscriber');
            appClientEventSubscriber.subscribeEvents();
        },

        runMain: async ({resolve}) => {
            const appManager = resolve('appManager');
            await appManager.runApp();
        }

    };
}