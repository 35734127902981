import {ComponentViewWrapperProps} from '@webaker/app';
import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {HiddenComponent} from './hidden-component';
import {HiddenComponentViewWrapperCSS} from './hidden-component-view-wrapper-css';

export interface HiddenComponentViewWrapperProps extends ComponentViewWrapperProps<HiddenComponent> {

}

export function HiddenComponentViewWrapper({Component, component, childComponents, render, className, elementRef}: HiddenComponentViewWrapperProps) {

    const css = useThemedCSS(HiddenComponentViewWrapperCSS);

    return <Component component={component}
                      childComponents={childComponents}
                      render={render}
                      className={mergeClassNames(
                          component.hidden && css[`hidden-${component.hidden}`],
                          className
                      )}
                      elementRef={elementRef}/>;

}