import {createAdminUserClientProvider} from '@webaker/module-admin-user/client';
import {createCommonPageClientProvider} from '@webaker/module-common-page/client';
import {createDesktopThemeClientProvider} from '@webaker/module-desktop-theme/client';
import {createErrorPageClientProvider} from '@webaker/module-error-page/client';
import {createFileManagerPageClientProvider} from '@webaker/module-file-manager-page/client';
import {createHiddenComponentClientProvider} from '@webaker/module-hidden-component/client';
import {createHomePageClientProvider} from '@webaker/module-home-page/client';
import {createHoverThemeClientProvider} from '@webaker/module-hover-theme/client';
import {createLoginPageClientProvider} from '@webaker/module-login-page/client';
import {createMobileThemeClientProvider} from '@webaker/module-mobile-theme/client';
import {createSettingsPageClientProvider} from '@webaker/module-settings-page/client';
import {createSlotComponentClientProvider} from '@webaker/module-slot-component/client';
import {createThemeComponentClientProvider} from '@webaker/module-theme-component/client';
import {createUserManagerPageClientProvider} from '@webaker/module-user-manager-page/client';
import {createWebsiteManagerPageClientProvider} from '@webaker/module-website-manager-page/client';

export type ModulesClientAppProvider = ReturnType<typeof getModulesClientAppProviders>[number];

export function getModulesClientAppProviders() {
    return [
        createAdminUserClientProvider(),
        createCommonPageClientProvider(),
        createDesktopThemeClientProvider(),
        createErrorPageClientProvider(),
        createFileManagerPageClientProvider(),
        createHiddenComponentClientProvider(),
        createHomePageClientProvider(),
        createHoverThemeClientProvider(),
        createLoginPageClientProvider(),
        createMobileThemeClientProvider(),
        createSettingsPageClientProvider(),
        createSlotComponentClientProvider(),
        createThemeComponentClientProvider(),
        createUserManagerPageClientProvider(),
        createWebsiteManagerPageClientProvider()
    ] as const;
}